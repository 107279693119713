<template>
  <div class="container page">
      <van-nav-bar :title="$t('setting.title')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="items">
        <div class="item van-hairline--bottom" @click="toInfomation()">
          <div class="left">{{ $t("setting.basic") }}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toLoginPassword()">
          <div class="left">{{ $t("setting.password") }}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toPayPassword()">
          <div class="left">{{ $t("setting.capital") }}</div>
          <div class="right">
            <span class="desc">{{ names(this.userInfo.paypassword)}}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="item van-hairline--bottom" @click="toLang()">
          <div class="left">{{ $t("setting.lang") }}</div>
          <div class="right">
            <span class="desc">{{ nameLangs() }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{ $t("setting.quit") }}</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:{},
      lang:"",
      list:{
        "zh": "中文 ",
        "jp": "にほんご",
        "ara": "بالعربية ",
        "en": "English ",
        "spa": "Español ",
        "it": "Italiano ",
        "de": "Deutsch ",
        "pt": "Português ",
        "fra": "Français",
        "nl": "Nederlands",
        "id": "Indonesia",
        "hi": "हिंदी भाषा",
        "kor": "한국어",
        "tr": "Türkçe",
        "swe": "Svenska språket",
        "ga": "Íris",
        "ru": "Русский язык",
        "vie": "Tiếng Việt",
        "th": "ภาษาไทย",
        est: "Eesti keel",
        be: "Беларуская мова",
        bul: "Български",
        pl: "Język polski",
        ms: "Bahasa Malay",
      }
    };
  },
  methods: {
    nameLangs(){
      return this.list[this.lang];
    },
    back(){
      return window.history.back();
    },
    names(paypassword){
      if(paypassword == "未设置"){
          return this.$t("msg.未设置")
      }else{
        return this.$t("msg.已设置")
      }
    },
    toPayPassword(){
      if(this.userInfo.paypassword !== "未设置"){
        this.$toast(this.$t("msg.提现密码已设置，需要修改请联系客服"));
      }else {
        this.$router.push({path:'/SetPayPassword'});
      }
    },
    toLang(){
      this.$router.push({path:'/SetLang'});
    },
    toLoginPassword(){
      this.$router.push({path:'/SetLoginPassword'});
    },
    toInfomation(){
      this.$router.push({path:'/Infomation'});
    },
    loginout(){
      localStorage.clear();
      localStorage.setItem('lang',this.lang);
      this.$router.push({path:'/Mine'});
    },
    toServicePage(){
      this.$router.push("ServicePage");
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          if(res.data.paypassword){
            this.userInfo.paypassword = "已设置";
          }else {
            this.userInfo.paypassword = "未设置";
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    this.lang = localStorage.getItem('lang');
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .items{
  background-color: #fff;
  font-size: 16PX;
  color: #000;
  padding: 0 25PX;
}
.container .items .item{
  padding: 18PX 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10PX;
}
.container .items .van-hairline--bottom::after {
  border-bottom-width: 3PX;
}
.container .sign-out{
  margin: 50PX auto 0;
  height: 50PX;
  line-height: 50PX;
  border-radius: 10PX;
  color: #fff;
  font-size: 18PX;
  font-weight: bolder;
  border: none;
  background: linear-gradient(-180deg,#fc78af,#f22678);
  width: 90%;
}
.container  .item .desc{
  font-size: 16PX;
  font-weight: 700;
  color: #979799;
}
.container .item .right{
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
