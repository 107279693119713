const Est = {
    text: {
        txt37: "Hääletamise protokoll",
        choose: "sobivus",
        bond: "Kinnitatud ja makstud deposiit $ 10000",
        img: "Portreekujutis",
        intention: "Jala hälve",
        casual: "Juhusliku seksi juhised",
        txt1: "Märkus: See platvorm pakub kõige autentsemaid lennutranspordi konksu ja silmuse tooteid. Kasutaja privaatsuse kaitsmiseks nõuab liikmelisus klienditeenindusega ühendust või edukate isikute soovitamist.",
        txt2: "Klienditeeninduse nõustamine",
        txt3: "juhend",
        txt4: "Võta üles oma õe number ja võta ühendust klienditeenindusega",
        ok: "OK",
        cancel: "tühista",
        txt5: "Kõigi liikmete huvides kuvatakse veebisaidil ainult 1% ressurssidest ja uuendused on ebaregulaarsed. Üksikasjalike ressursside saamiseks võtke palun ühendust klienditeenindusega.",
        txt6: "Kõik platvormil olevad liikmete andmed on ehtsad, turvalised ja usaldusväärsed. Seda saab kasutada üleriigiliselt ning samal tänaval võib leida ka uusi kohtumisi. Vali naine, kes sulle meeldib ja kohtu temaga isiklikult. Liitu nüüd~",
        txt7: "Liikmete teave",
        txt8: "sobivus",
        txt9: "Protokolli kasutamine",
        txt10: "Ma tahan temaga kohtingule minna.",
        txt11: "See platvorm pakub kõige autentsemat otsingut deflatsiooni. Isikuandmete privaatsuse kaitsmiseks saab registreerimist teha ainult kontakteerudes klienditoega või aktsepteerides pärisnimesoovitusi isikutelt, kes on edukalt deflatsioonikogemust otsinud.",
        txt12: "Praegu 0 sidumist",
        txt13: "44 aktiveerimine",
        txt14: "Liikmesriigi hüvitised",
        txt15: "sündmus",
        txt16: "Liikme video",
        txt17: "Valeta talle.",
        txt18: "Liikmete teave",
        txt19: "Uus ID",
        txt20: "kohtuda",
        txt21: "Hääletamine lõpeb",
        txt22: "Perioodi tulemused",
        txt23: "periood",
        txt24: "Juhuslikult vali number",
        credit_score: "Krediidiskoor",
        mail: "Jaama kiri",
        txt25: "Ajakohastamine nõuab 15 protsendipunkti",
        txt26: "Isiklikud punktid",
        txt27: "Punkt ( 8361;)",
        txt28: "Punktide laadimine",
        txt29: "Asukoha muutmine",
        txt30: "veebiteenus",
        txt31: "lühike sissejuhatus",
        txt32: "Klõpsa üksikasjalikule teabele",
        txt33: "Vahetus jätkub",
        txt34: "andmekirje",
        txt35: "Parooli muutmine",
        txt36: "Asukoha muutmine",

    },
    login: {
        title: "Logige sisse",
        username: "Palun sisestage kasutajanimi",
        password: "Palun sisestage parool",
        forgetPaw: "Unusta parool",
        registerNow: "Kontot pole? Registreeruge kohe",
        doLogin: "Logige sisse",

    },
    register:{
        title: "Registreerimine",
        username: "Palun sisestage kasutajanimi (6-12 ingliskeelset tähte või numbrit)",
        password: "Palun sisestage sisselogimisparool (6-12 ingliskeelset tähte või numbrit)",
        code: "Palun sisestage kutsekood",
        agreement: "Ma juba tean ja nõustun konto avamise lepingu lepingutega.",
        chooseAgreement: "Palun kontrollige allpool olevat konto avamise lepingut!",
        doRegister: "Registreerimine",

    },
    footer:{
        home : "Esileht",
        game: "Ametisse nimetamine",
        video: "Video",
        mine: "Minu",

    },
    game:{
        title: "Ametisse nimetamise saal",
        all: "Kõik",

    },
    choose:{
        title: "Valige",
        city: "City Daquan",
        rule: "Platvormi kõige autentsem + äri kaaslase + sama linna kirg, et tagada iga kasutaja privaatsus, saavad kliendid liituda ainult administraatori või platvormi vanema liikme tõelise nimega.",
        price: "Hinnaprotsess",
        resource: "Millised ressursid on olemas?",
        character: "Net punane, mudel, stjuardess, pakkumine mudel, kolledži üliõpilased, ainult te ei saa seda mõelda, ei ole lamedat",
        scope: "Teenuse ulatus?",
        location: "Sama linna tasuta relvad, mis tahes kohas asuvad riigi õhupallid, kohalikud esimese ja teise tasandi linnad Hiinas, kolmanda taseme linnad võivad ka korraldada kohtumisi, võttes ühendust administraatoriga.",

    },
    home:{
        recommend: "Soovituslik ülesanne",
        more: "Vaata rohkem",
        popularity: "Populaarne edetabel",
        hot: "Populaarne soovitus",

    },
    lottery:{
        jieguo: "Hääletamise tulemus",
        jiesu: "Lõpeta",
        record: "Ülesande kirje",
        rule: "Mängureeglid",
        prompt: "Mängige näpunäiteid",
        gameplay: "Valige valikulise ja väärtuse vormi number panustamiseks",
        explain: "Võitnud kirjeldus",
        wprole: "Kolme loteriinumbri summa väärtus on 11-18; Summa väärtus 3 ~ 10 on väike;",
        example: "Panuse näide",
        option: "Panustamiskava: väike loteriinumber: 123, st väike ja keskmise suurusega",
        task: "Ülesande nimekiri",
        money: "Olemasolev saldo",
        submit: "Esitatud",
        selection: "Praegune valimisnumber",
        perNote: "Iga märkus summa kohta",
        inputMoney: "Palun sisestage summa",
        total: "Kokku",
        note: "Kokku",
        statistics: "Kokku",
        empty: "Tühjendage tellimus",
        verify: "Kinnitage esitamine",
        issue: "Ajavahemik",
        num: "Võitlus number",
        big: "Suur",
        small: "Väike",
        single: "Üksik",
        double: "Kahekordne",
        unselected: "Vali pole",
        drawLottery: "Edukas auhind, perioodi number:",
        kefu: "Palun võtke ülesande täitmiseks ühendust administraatoriga!",

    },
    mine:{
        recharge: "Laadimine",
        withdraw: "Väljavõte",
        myMoney: "Minu rahakott",
        detail: "Üksikasjad",
        balance: "Tasakaal",
        finance: "Isiklik aruanne",
        order: "Konto andmed",
        record: "Ülesande kirje",
        my: "Isiklik keskus",
        notice: "Teabeteade",
        service: "Online klienditeenindus",
        score: "Punkt",
    },
    setting:{
        title: "Seadista",
        basic: "Põhiteabe seadistamine",
        password: "Sisselogimisparool",
        capital: "Fondi parool",
        lang: "Keel",
        quit: "Väljuge sisselogimisest",

    },
    setPayPassword: {
        title: "Määrake fondi parool",
        opw: "Palun sisestage fondi parool",
        tpw: "Palun sisestage uuesti fondi parool",

    },
    gameRecord:{
        reject: "tagasi lükata",
        profit: "kasum",
        place: "esita tellimus",
        title: "Ülesande kirje",
        amountBet: "Panuse summa",
        create: "Tellimisaeg",
        update: "Arveldusaeg",

    },
    video:{
        title: "Videoteater",
        play: "Esita",

    },
    playVideo:{
        play: "Sekundaarne taasesitus",

    },
    setname:{
        title: "Muutke tegelikku nime",
        name: "Nimi",
        truename: "Palun sisestage oma tegelik nimi",
        hint: "Teie konto turvalisuse tagamiseks peab tegelik nimi olema kooskõlas pangakaardi nimega.",

    },
    setsex:{
        title: "Sugu muutmine",

    },
    bindCard:{
        title: "Täitke kassikaart",
        msg: "Palun sisestage oma kassikaardi teave",
        hint: "Lugupeetud kasutajad, et kaitsta oma rahalisi vahendeid, palun siduge oma tegelik nimi ja määrake tagasivõtmise parool. Kui nimi ei vasta konto avamise nimele, ei saa te raha välja võtta.",
        card: "Konto number (tavaline)",
        truecard: "Sisestage konto number (tavaline)",
        name: "Konto nimi (tegelik nimi)",
        truename: "Sisestage konto nimi (tegelik nimi)",
        financial_institution: "Finantsasutuse nimi",
        truefinancial_institution: "Sisestage finantsasutuse nimi",
        branch: "Filiaali nimi",
        truebranch: "Sisestage filiaali nimi",
        branch_number: "Filiaali number",
        truebranch_number: "Sisestage rea number"
    },
    setbank:{
        title: "Kogumiskaardi teave",
        bank: "Lisage pangakaart",
        hint: "Näpunäide: palun seota suur kommertspank. Kui teil on vaja muuta, võtke ühendust veebiklienditeenindusega.",

    },
    setLoginPassword:{
        title: "Muutke sisselogimisparooli",
        old_password: "Vana parool",
        old_password_p: "Palun sisestage vana parool",
        o_new_password: "Uus parool",
        o_new_password_p: "Palun sisestage uus parool",
        t_new_password_p: "Palun sisestage uus parool uuesti",

    },
    infomation:{
        title: "Põhiteave",
        head: "Avatar",
        select: "Valige avatari",
        name: "Tõeline nimi",
        sex: "Sugu",
        man: "Mees",
        girl: "Naine",
        unknown: "Tundmatu",
        binding: "Seotud teave",

    },
    withdraw: {
        title: "Edastuskeskus",
        record: "Rahaülekanne",
        money: "Raha väljavõtmine",
        all: "Kõik",
        min: "Ühekordne piirang: minimaalne",
        max: "",
        num: "Lisamine: üks päev",
        hint: "Saabumisaeg: tavaliselt saabumisaeg on umbes 5 minutit, nii kiiresti kui 2 minutit.",
        quota: "Kvootide kirjeldus",
        balance: "Tasakaal",
        withdraw: "Nimetage kohe",

    },
    withdrawRecord:{
        desc: "Kirjeldus",
        create: "Esitamise aeg",
        update: "Auditi aeg",

    },
    personalreport:{
        title: "Isiklik aruanne",
        profit: "Kasum",
        formula: "Kasumi arvutamise valem: võitnud summa-ülesande summa",
        task: "Ülesande summa",
        recharge: "Laadimissumma",
        withdrawal: "Raha väljavõtmine",
        win_money: "Võitnud summa",

    },
    service: {
        title: "Online klienditeenindus",
        relation: "Kontakt",
        hint: "7 * 24 tundi ööpäevas, teenides teid terve rida",

    },
    profile: {
        portrait: "Fotograafia",
        video: "Video",
        jianjie: "Isiklik profiil",
        liji: "Kohe kohtumine",
        chongdan: "Laadimisvahemik",
        fd: "Daam",
        yuyue: "Ametisse nimetamine",
        archives: "Õde fail",
        age: "Vanus",
        height: "Kõrgus",
        weight: "Kaalu",
        cup: "Tass",
        city: "Linn",
        area: "Teeninduspiirkond",
        item: "Teenusprojekt"
    },
    msg:{
        "次": "Teine kord",
        "用户不存在！": "Kasutajad ei eksisteeri!",
        "密码错误！": "Parool on vale!",
        "登录成功！": "Edukas sisselogimine!",
        "邀请码不存在！": "Kutse koodi ei eksisteeri!",
        "用户名位数错误！": "Kasutajanimi number on vale!",
        "密码位数错误！": "Parooli number on vale!",
        "用户名不能存在中文！": "Kasutajanimi ei saa olla hiina keeles!",
        "邀请码不能为空！": "Kutse kood ei saa olla tühi!",
        "注册成功！": "Registreerimine on edukas!",
        "注册失败！": "Registreerimine ebaõnnestus!",
        "用户已存在": "Kasutaja on juba olemas",
        "刷新成功！": "Värskendus on edukas!",
        "请联系顾问或接待员": "Palun võtke ühendust konsultandi või administraatoriga",
        "金额错误！": "Summa on vale!",
        "请选号！": "Palun vali number!",
        "请填写金额!": "Palun täitke summa!",
        "余额不足，请联系客服充值！": "Ebapiisav tasakaal, võtke palun ühendust klienditeenindusega!",
        "没有更多了": "Mitte rohkem",
        "没有数据": "Andmeid pole",
        "余额不足！": "Ebapiisav tasakaal!",
        "扣款失败！": "Mahaarvamine ebaõnnestus!",
        "下注异常！": "Panus on ebanormaalne!",
        "投注成功！": "Panuse edu!",
        "参数异常！": "Parameetrid on ebanormaalsed!",
        "获取中": "Hankige...",
        "请完成任务单后进入": "Palun täitke ülesannete nimekiri",
        "请联系客服充值": "Palun võtke ühendust klienditeeninduse laadimisega",
        "请设置收款卡!": "Palun seadke kassikaart!",
        "功能已禁用!": "Funktsioon on keelatud!",
        "账号下线": "Konto number on võrguühenduseta",
        "登录/注册": "Sisselogimine/registreerimine",
        "登录可享受更多服务！": "Logi sisse ja naudi rohkem teenuseid!",
        "未设置": "Määramata",
        "已设置": "Seatud",
        "提交": "Esitatud",
        "确定": "Kindlaksmääramine",
        "请填写完整": "Palun täitke",
        "两次密码不一致！": "Kaks parooli on ebajärjekindlad!",
        "设置提现密码成功！": "Määrake kassapargi edu!",
        "提现密码已设置，需要修改请联系客服": "Kviitungi parool on määratud, palun võtke ühendust klienditeenindusega, kui soovite seda muuta.",
        "已绑定": "Seotud",
        "无": "Ei",
        "更换头像成功！": "T asendab avatari edukalt!",
        "更新头像失败！": "Avatari värskendamine ebaõnnestus!",
        "请勿重复设置！": "Ärge korrake seadeid!",
        "设置姓名成功！": "Määrake nimi edukaks!",
        "设置姓名失败！": "Nime määramine ebaõnnestus!",
        "设置性别成功！": "Määrake sooline edu!",
        "设置性别失败！": "Sugu määramine ebaõnnestus!",
        "请设置姓名后再绑定银行卡！": "Palun määrake nimi ja siduge pangakaart!",
        "请设置提现密码后再绑定银行卡！": "Palun määrake kassapargi ja siduge pangakaart!",
        "确认绑卡": "Kinnitage siduvat kaarti",
        "请输入银行卡号！": "Palun sisestage pank!",
        "请输入银行名称！": "Palun sisestage panga nimi!",
        "设置银行卡成功！": "Pangakaardi seadistamine on edukas!",
        "设置银行卡失败！": "Pangakaardi seadistamine ebaõnnestus!",
        "旧密码错误": "Vana parooli viga",
        "修改密码成功！": "Parooli muutmine on edukas!",
        "修改密码失败！": "Parooli muutmine ebaõnnestus!",
        "请填写正确的金额": "Palun täitke õige summa",
        "提现金额不能少于：": "Väljundi summa ei tohi olla väiksem kui:",
        "提现金额不能大于：": "Väljamakse summa ei tohi olla suurem kui:",
        "当日提现次数已用完": "See on ära kasutatud samal päeval.",
        "提现成功！": "Edukas!",
        "提现失败！": "See ebaõnnestus!",
        "金额": "Summa",
        "待审核": "Oodatav läbivaatamine",
        "审核成功": "Edukas ülevaade",
        "审核退回": "Auditi tagastamine",
        "请充值后观看视频！": "Palun laadige video pärast laadimist!",
        "释放即可刷新": "Vabastust saab värskendada...",
        "下拉即可刷新": "Tõmmake ja värskendage...",
        "加载中": "Laadimisel...",

    },
    setLang:{
        title: "Määrake keel"
    },
    lang:{
        zh: "Hiina",
        jp: "Jaapan",
        ara: "Keel",
        en: "Inglise keel",
        spa: "Hispaania keel",
        it: "Itaalia keel",
        de: "Saksa keel",
        pt: "Portugali keel",
        fra: "Prantsuse keel",
        nl: "Hollandi keel",
        id: "Indini",
        hi: "Hindi",
        kor: "Korea",
        tr: "Türgi",
        swe: "Rootsi keel",
        ga: "Iiri keel",
        ru: "Vene keel",
        vie: "Vietnami keel",
        th: "Tai",
        est: "Eesti keel",
        be: "Valgevene",
        bul: "Bulgaaria keel",
        pl: "Poola",
        ms: "Malai keel",

    }
}
export default Est