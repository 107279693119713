const Ru = {
    text: {
        txt37: "Отчеты о голосовании",
        choose: "Соответствие",
        bond: "Были подтверждены и оплачены депозиты в размере 10 000 долл. США",
        img: "Портрет показывает",
        intention: "Экономическая достаточность мандарина",
        casual: "Сексуальные инструкции для отдыха.",
        txt1: "Примечание: Данная платформа предлагает самые настоящие воздушные крючковые кольца. Чтобы защитить конфиденциальность пользователей, членство должно быть рекомендовано службой обслуживания клиентов или успешными людьми.",
        txt2: "Консультации с клиентами",
        txt3: "Мастер",
        txt4: "Запишите номер сестры и свяжитесь с клиентом.",
        ok: "Ладно.",
        cancel: "Отменить",
        txt5: "Для каждого члена на сайте отображается только 1% ресурсов, обновления также нерегулярны. Для получения подробной информации о ресурсах, пожалуйста, свяжитесь с клиентской службой.",
        txt6: "Вся информация о членах на платформе является достоверной, безопасной и надежной. Его можно использовать по всей стране, а также найти новые встречи на той же улице. Выбирайте понравившуюся женщину и встречайте ее на самом деле. Присоединяйтесь сейчас же",
        txt7: "Информация о членах",
        txt8: "Соответствие",
        txt9: "Использование Статута",
        txt10: "Я хочу с ней встречаться.",
        txt11: "Эта платформа предлагает самый реальный дефляционный поиск. Чтобы защитить конфиденциальность личной информации, регистрация может быть зарегистрирована только для тех, кто связывается с поддержкой или принимает рекомендации по реальным именам людей, которые успешно ищут опыт дефляции.",
        txt12: "В настоящее время 0 онлайн",
        txt13: "44 Активация.",
        txt14: "Членский капитал",
        txt15: "События",
        txt16: "Видео участника",
        txt17: "Обмануть его.",
        txt18: "Информация о членах",
        txt19: "Новый ID",
        txt20: "Встреча",
        txt21: "Окончание голосования",
        txt22: "Результаты периода",
        txt23: "Период",
        txt24: "Выбор номера",
        credit_score: "Кредитный рейтинг",
        mail: "Станционное письмо",
        txt25: "Для повышения требуется 15%",
        txt26: "Индивидуальный интеграл",
        txt27: "Точка (8361;)",
        txt28: "Интегрированное пополнение",
        txt29: "Замена битов",
        txt30: "Онлайновые услуги",
        txt31: "Введение",
        txt32: "Подробная информация",
        txt33: "Обмен биографическими данными",
        txt34: "Регистрация данных",
        txt35: "Смена пароля",
        txt36: "Замена битов",
    },
    login: {
        title: "войти",
        username: "Введите имя пользователя",
        password: "Введите пароль",
        forgetPaw: "забыть пароль",
        registerNow: "Счета нет? Регистрируйтесь прямо сейчас",
        doLogin: "войти",

    },
    profile: {
        portrait: "Фотография",
        video: "ВИДЕО",
        jianjie:" Личные сведения",
        liji: "Записаться на прием",
        chongdan: "диапазон зарядки",
        fd: "дорогая женщина",
        yuyue: "записаться",

        archives: "дело сестры",
        age: "Возраст",
        height: "высота",
        weight: "вес",
        cup: "чашка",
        city: "Город",
        area: "зона обслуживания",
        item: "Предметы обслуживания"
    },
    register:{
        title: "регистрация",
        username: "Введите имя пользователя (6-12 английских букв или цифр)",
        password: "Введите пароль входа (6-12 английских букв или цифр)",
        code: "Введите код приглашения",
        agreement: "Я уже знаю и согласен с договорами « Соглашения об открытии счета».",
        chooseAgreement: "Пожалуйста, отметьте ниже протокол открытия счета!",
        doRegister: "регистрация",

    },
    footer:{
        home : "первая страница",
        game: "записаться",
        video: "ВИДЕО",
        mine: "Мой",

    },
    game:{
        title: "зал предварительной встречи",
        all: "Все",

    },
    choose:{
        title: "выбрать наложницу",
        city: "городская книга",
        rule: "Самое настоящее во всей сети данной платформы + бизнес-эскорт + страсть в одном городе. Для обеспечения личной неприкосновенности каждого пользователя клиенты могут присоединиться к ней только путем обращения к регистратору или с настоящими именами старших членов платформы.",
        price: "ценовой процесс",
        resource: "Какие ресурсы имеются?",
        character: "Чистая красная, модель, стюардесса, нежная модель, студент колледжа, только вы не можете себе представить, ни один Бен Пин не может",
        scope: "Сфера услуг?",
        location: "В одном городе бесплатно записаны орудия, в любом месте по всей стране десантируются воздушные десанты, в городах первой и второй линии страны они есть на местах, в городах третьей линии также можно записаться на прием, связавшись с регистратором.",

    },
    home:{
        recommend: "рекомендуемое задание",
        more: "Смотреть больше",
        popularity: "Рейтинг популярности",
        hot: "Популярные рекомендации",

    },
    lottery:{
        jieguo: "результат голосования",
        jiesu: "заканчивать",
        record: "Запись о задании",
        rule: "правила игры",
        prompt: "подсказки по игре",
        gameplay: "Выберите номер из необязательной и значенной формы, чтобы сделать ставку",
        explain: "описание выигрыша",
        wprole: "Сумма трех розыгрышных номеров 11–18 – большая; Сумма 3-10 мала;",
        example: "пример ставки",
        option: "Схема ставок: Маленький розыгрыш Номер: 123, то есть маленький и средний",
        task: "задание",
        money: "свободный остаток",
        submit: "представлять",
        selection: "Текущий выбор",
        perNote: "Каждая сумма",
        inputMoney: "Введите сумму",
        total: "Всего",
        note: "Всего",
        statistics: "Итого",
        empty: "опустошить заказ",
        verify: "Подтверждение представления",
        issue: "№ очереди",
        num: "номер лотереи",
        big: "большой",
        small: "малый",
        single: "листок",
        double: "двойной",
        unselected: "Не выбрано",
        drawLottery: "Успешный розыгрыш, номер номера:",
        kefu: "Свяжитесь с администратором для этой задачи!",

    },
    mine:{
        recharge: "пополнение",
        withdraw: "снятие наличных",
        myMoney: "Мой кошелек",
        detail: "Подробности",
        balance: "остаток",
        finance: "личная ведомость",
        order: "реквизиты счетов",
        record: "Запись о задании",
        my: "Личный центр",
        notice: "информационное объявление",
        service: "Онлайн-обслуживание клиентов",
        score: "интеграл",
    },
    setting:{
        title: "настройка",
        basic: "Настройка основной информации",
        password: "пароль входа",
        capital: "пароль средств",
        lang: "язык",
        quit: "выйти из логина",

    },
    setPayPassword: {
        title: "Установить пароль средств",
        opw: "Введите пароль средств",
        tpw: "Пожалуйста, введите пароль для средств еще раз",

    },
    gameRecord:{
        reject: "Отклонено",
        profit: "Прибыль",
        place: "Выписать заказ",
        title: "Запись о задании",
        amountBet: "сумма ставки",
        create: "время размещения заказа",
        update: "время расчета",

    },
    video:{
        title: "Видеокинотеатр",
        play: "воспроизведение",

    },
    playVideo:{
        play: "вторичное воспроизведение",

    },
    setname:{
        title: "Изменить настоящее имя",
        name: "Имя и фамилия",
        truename: "Введите настоящее имя",
        hint: "Для безопасности вашего счета настоящее имя должно совпадать с именем привязанной банковской карты",

    },
    setsex:{
        title: "Изменение пола",

    },
    bindCard:{
        title: "Заполнение инкассаторской карточки",
        msg: "Введите информацию о карте получения",
        hint: "Уважаемый пользователь, для обеспечения безопасности ваших средств, пожалуйста, привяжите ваше настоящее имя и установите пароль для снятия денег, если ваше имя не совпадает с именем открытия счета, вы не сможете снять деньги",
        card: "Номер счета (общий)",
        truecard: "Введите номер счета (обычный)",
        name: "Имя счета (псевдоним)",
        truename: "Введите имя учётной записи (псевдоним)",
        financial_institution: "Название финансового учреждения",
        truefinancial_institution: "Введите наименование финансового учреждения",
        branch: "название филиала",
        truebranch: "Введите наименование филиала",
        branch_number: "№ отделения",
        truebranch_number: "Введите номер отделения"
    },
    setbank:{
        title: "Информация о карточке получения",
        bank: "Добавить банковскую карту",
        hint: "Совет: пожалуйста, свяжитесь с крупным коммерческим банком, если вам нужно изменить, пожалуйста, свяжитесь со службой поддержки онлайн",

    },
    setLoginPassword:{
        title: "Изменить пароль входа в систему",
        old_password: "старый пароль",
        old_password_p: "Введите старый пароль",
        o_new_password: "Новый пароль",
        o_new_password_p: "Введите новый пароль",
        t_new_password_p: "Пожалуйста, введите новый пароль снова",

    },
    infomation:{
        title: "основная информация",
        head: "аватар",
        select: "Выбрать аватар",
        name: "настоящая фамилия",
        sex: "Пол",
        man: "Мужчины",
        girl: "женщина",
        unknown: "Неизвестно",
        binding: "информация о привязке",

    },
    withdraw: {
        title: "центр снятия наличных",
        record: "запись о снятии наличных",
        money: "сумма снятия наличных",
        all: "Все",
        min: "Лимит одной ручки: минимальный",
        max: "",
        num: "Снятие наличных: снятие наличных в течение одного дня",
        hint: "Время прибытия: Обычное время прибытия составляет около 5 минут, быстрейшее прибытие в течение 2 минут",
        quota: "Описание пределов",
        balance: "остаток",
        withdraw: "немедленное снятие наличных",

    },
    withdrawRecord:{
        desc: "пояснение",
        create: "Сроки представления",
        update: "время рассмотрения",

    },
    personalreport:{
        title: "личная ведомость",
        profit: "сумма прибыли",
        formula: "Формула расчета прибыли: сумма выигрыша-сумма задания",
        task: "Сумма задания",
        recharge: "сумма пополнения",
        withdrawal: "сумма снятия наличных",
        win_money: "сумма выигрыша",

    },
    service: {
        title: "Онлайн-обслуживание клиентов",
        relation: "связь",
        hint: "7 * 24 часа в день",

    },
    msg:{
        "次": "раз",
        "用户不存在！": "Пользователей не существует!",
        "密码错误！": "Ошибка пароля!",
        "登录成功！": "Вход удачен!",
        "邀请码不存在！": "Код приглашения не существует!",
        "用户名位数错误！": "Ошибка в цифрах имени пользователя!",
        "密码位数错误！": "Ошибка в шифре!",
        "用户名不能存在中文！": "Имя пользователя не может существовать на китайском языке!",
        "邀请码不能为空！": "Код приглашения не может быть пустым!",
        "注册成功！": "Регистрация прошла успешно!",
        "注册失败！": "Неудачная регистрация!",
        "用户已存在": "Пользователь уже существует",
        "刷新成功！": "Освежайте успех!",
        "请联系顾问或接待员": "Пожалуйста, свяжитесь с консультантом или регистратором",
        "金额错误！": "Неверная сумма!",
        "请选号！": "Выберите номер, пожалуйста!",
        "请填写金额!": "Укажите сумму!",
        "余额不足，请联系客服充值！": "Баланс недостаточен, пожалуйста, свяжитесь со службой поддержки для пополнения",
        "没有更多了": "больше нет",
        "没有数据": "Данные отсутствуют",
        "余额不足！": "Не хватает остатка!",
        "扣款失败！": "Неудачный вычет!",
        "下注异常！": "Аномальные ставки!",
        "投注成功！": "Ставка удалась!",
        "参数异常！": "Параметры ненормальные!",
        "获取中": "получить что",
        "请完成任务单后进入": "Пожалуйста, после выполнения задания",
        "请联系客服充值": "Пожалуйста, свяжитесь со службой поддержки, чтобы пополнить счет",
        "请设置收款卡!": "Пожалуйста, установите карточку получения!",
        "功能已禁用!": "Функция отключена!",
        "账号下线": "офлайн аккаунта",
        "登录/注册": "Регистрация/регистрация",
        "登录可享受更多服务！": "Вход для получения дополнительных услуг!",
        "未设置": "Не установлено",
        "已设置": "Установлено",
        "提交": "представлять",
        "确定": "устанавливать",
        "请填写完整": "Пожалуйста, заполните его полностью",
        "两次密码不一致！": "Дважды не совпадали пароли!",
        "设置提现密码成功！": "Установить пароль снятия наличных успешно!",
        "提现密码已设置，需要修改请联系客服": "Пароль для снятия наличных установлен, пожалуйста, свяжитесь со службой поддержки для изменения",
        "已绑定": "привязан",
        "无": "У",
        "更换头像成功！": "Т Замена аватара успешно!",
        "更新头像失败！": "Не удалось обновить аватар!",
        "请勿重复设置！": "Не повторяйте настройки!",
        "设置姓名成功！": "Установить имя успешно!",
        "设置姓名失败！": "Не удалось установить имя!",
        "设置性别成功！": "Установить гендерный успех!",
        "设置性别失败！": "Не удалось установить пол!",
        "请设置姓名后再绑定银行卡！": "Пожалуйста, установите имя, прежде чем привязывать банковскую карту!",
        "请设置提现密码后再绑定银行卡！": "Пожалуйста, установите пароль снятия наличных, прежде чем привязывать банковскую карту!",
        "确认绑卡": "Подтверждение привязки карты",
        "请输入银行卡号！": "Введите банк, пожалуйста!",
        "请输入银行名称！": "Пожалуйста, введите название банка!",
        "设置银行卡成功！": "Настройка банковской карты прошла успешно!",
        "设置银行卡失败！": "Не удалось установить банковскую карту!",
        "旧密码错误": "Ошибка старого пароля",
        "修改密码成功！": "Изменение пароля успешно!",
        "修改密码失败！": "Не удалось изменить пароль!",
        "请填写正确的金额": "Укажите правильную сумму",
        "提现金额不能少于：": "Сумма снятия наличных не может быть менее:",
        "提现金额不能大于：": "Сумма снятия наличных не должна превышать:",
        "当日提现次数已用完": "Снятые наличные на этот день были израсходованы",
        "提现成功！": "Снятие наличных успешно!",
        "提现失败！": "Не удалось снять наличные!",
        "金额": "Сумма",
        "待审核": "Подлежит рассмотрению",
        "审核成功": "Успешное рассмотрение",
        "审核退回": "возврат на рассмотрение",
        "请充值后观看视频！": "Пожалуйста, посмотрите видео после пополнения баланса!",
        "释放即可刷新": "Освободите его, чтобы обновить...",
        "加载中": "Загрузить...",
        "下拉即可刷新": "Опуститесь вниз, чтобы обновить...",
    },
    setLang:{
        title: "язык настройки"
    },
    lang:{
        zh: "Китайский язык",
        jp: "Япония",
        ara: "язык",
        en: "Английский язык",
        spa: "испанский",
        it: "Итальянский язык",
        de: "Немецкий язык",
        pt: "португальский",
        fra: "Французский язык",
        nl: "голландский язык",
        id: "индонезийский язык",
        hi: "хинди",
        kor: "корейский язык",
        tr: "турецкий язык",
        swe: "шведский",
        ga: "ирландский язык",
        ru: "русский язык",
        vie: "вьетнамский язык",
        th: "тайский язык",
        est: "эстонский язык",
        be: "белорусский язык",
        bul: "болгарский язык",
        pl: "польский язык",
        ms: "малайский язык",
    }
}
export default Ru