const Vie = {
    text: {
        txt37: "Hồ sơ bỏ phiếu",
        choose: "Khớp",
        bond: "Đã được chứng nhận và thanh toán 10.000 đô la tiền đặt cọc",
        img: "Chân dung đại diện",
        intention: "経験 đầy đủ",
        casual: "Lời bài hát: Casual Sex",
        txt1: "Lưu ý: Nền tảng này cung cấp các móc và vòng lặp không khí chính hãng nhất. Để bảo vệ quyền riêng tư của người dùng, tư cách thành viên cần liên hệ với dịch vụ khách hàng hoặc được giới thiệu bởi những người thành công.",
        txt2: "Tư vấn dịch vụ khách hàng",
        txt3: "Hướng dẫn",
        txt4: "Ghi lại số điện thoại của chị gái, liên lạc với dịch vụ khách hàng.",
        ok: "Được rồi.",
        cancel: "Hủy bỏ",
        txt5: "Đối với các thành viên, chỉ có 1% tài nguyên được hiển thị trên trang web và cập nhật không thường xuyên. Để biết thêm thông tin, vui lòng liên hệ với dịch vụ khách hàng.",
        txt6: "Tất cả thông tin thành viên trên nền tảng là xác thực và an toàn. Có sẵn trên khắp đất nước, hoặc bạn có thể tìm thấy những cuộc gặp gỡ mới trên cùng một con phố. Chọn người phụ nữ mình thích, thực tế gặp mặt đi. Hãy tham gia ngay bây giờ~",
        txt7: "Thông tin thành viên",
        txt8: "Khớp",
        txt9: "Quy chế sử dụng",
        txt10: "Tôi muốn hẹn hò với cô ấy.",
        txt11: "Nền tảng này cung cấp tìm kiếm giảm phát chân thực nhất. Để bảo vệ quyền riêng tư của thông tin cá nhân, việc đăng ký chỉ có thể được thực hiện bởi những người liên hệ với bộ phận hỗ trợ hoặc chấp nhận đề xuất tên thật từ những người đã tìm kiếm thành công trải nghiệm giảm phát.",
        txt12: "Đang kết nối trực tuyến 0",
        txt13: "44 Kích hoạt",
        txt14: "Thành viên 権権",
        txt15: "Sự kiện",
        txt16: "Video thành viên",
        txt17: "Lừa hắn đi.",
        txt18: "Thông tin thành viên",
        txt19: "ID mới",
        txt20: "Gặp gỡ",
        txt21: "Cuộc bỏ phiếu kết thúc.",
        txt22: "Kết quả",
        txt23: "Thời gian",
        txt24: "Chọn tên",
        credit_score: "Điểm tín dụng",
        mail: "Thư trạm",
        txt25: "Cần 15% để nâng cấp",
        txt26: "Điểm cá nhân",
        txt27: "Điểm (8361;)",
        txt28: "Điểm nạp",
        txt29: "Thay thế vị trí",
        txt30: "Dịch vụ trực tuyến",
        txt31: "Giới thiệu",
        txt32: "Chi tiết điểm",
        txt33: "Sơ yếu lý lịch trao đổi",
        txt34: "Ghi dữ liệu",
        txt35: "Thay đổi mật khẩu",
        txt36: "Thay thế vị trí",
    },
    login: {
        title: 'Đăng nhập', //登录
        username:'Hãy nhập tên người dùng',//请输入用户名
        password:"Xin vui lòng nhập mật khẩu",//请输入密码
        forgetPaw:"Quên mật mã?",//忘记密码
        registerNow:"Không có tài khoản? Đăng ký ngay lập tức",//没有账户？马上注册
        doLogin:"Đăng nhập",

    },
    register:{
        title: 'Đăng ký', //注册
        username:'Hãy nhập tên người dùng (6-12 chữ cái hoặc số)',//请输入用户名(6-12个英文字母或数字)
        password:'Vui lòng nhập mật khẩu đăng nhập (6-12 chữ cái hoặc số)',//请输入登录密码(6-12个英文字母或数字)
        code:'Vui lòng nhập mã mời',//请输入邀请码
        agreement:'Tôi đã biết và đồng ý hiệp ước mở tài khoản',//我已经知晓并同意"开户协议"各项条约
        chooseAgreement:"Vui lòng đánh dấu ở phía dưới của giao thức mở tài khoản!",//请勾选下方开户协议！
        doRegister:"Đăng ký",
    },
    footer:{
        home :"Trang chủ",//首页
        game:"Đặt cược",//预约
        video:"Video Sex",//视频
        mine:"Cá nhân",//我的
    },
    game:{
        title:"Sảnh chính",//预约大厅
        all:"Tất cả",//全部
    },
    choose:{
        title:"chọn vợ lẽ",//选妃
        city:"Đô thành đại quan",//城市大全
        rule:"Nền tảng này là thực tế nhất ngoại vi + kinh doanh hộ tống + niềm đam mê, để đảm bảo quyền riêng tư cá nhân của mỗi người dùng, khách hàng chỉ được giới thiệu bằng cách liên hệ với tiếp tân hoặc các thành viên cao cấp tên thật của nền tảng để tham gia.",//本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。
        price:"Quá trình giá cả",//价格流程
        resource:"Nguồn lực là gì?",//有哪些资源?
        character:"Net đỏ, người mẫu, tiếp viên hàng không, người mẫu đấu thầu, sinh viên đại học, chỉ bạn nghĩ rằng, không có nền tảng không thể làm điều đó",//网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到
        scope:"Phạm vi dịch vụ?",//服务范围?
        location:"Về súng miễn phí trong thành phố, bất kỳ địa điểm quốc gia không vận, một hoặc hai thành phố trong nước địa phương, các thành phố thứ ba cũng có thể liên hệ với tiếp tân để sắp xếp một cuộc hẹn.",//同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。
    },
    home:{
        recommend:"Gợi ý Đặt cược",//推荐任务
        more:"Xem thêm",//查看更多
        popularity:"Xếp hạng những phim được xem phổ biến",//人气排行
        hot:"Giới thiệu những thước phịm nóng",//热门推荐
    },
    lottery:{
        jieguo: "Kết quả bình chọn",
        jiesu: "Kết thúc",
        record:"Nội dung Đặt cược",//任务记录
        rule:"Luật chơi",//玩法规则
        prompt:"Mẹo chơi",//玩法提示
        gameplay:"Chọn số để đặt cược từ các tùy chọn và giá trị",//从可选和值形态里面选择号码进行下注
        explain:"Mô tả chiến thắng",//中奖说明
        wprole:"Ba số xổ số tổng giá trị 11~18 là lớn; Tổng giá trị 3-10 là nhỏ;",//三个开奖号码总和值11~18 为大;总和值3~ 10为小;
        example:"Các mô hình cá cược",//投注范例
        option:"Cá cược chương trình: số xổ số nhỏ: 123, nhỏ",//投注方案：小 开奖号码：123,即中小
        task:"Giỏ hàng",//任务单
        money:"Số dư hiện tại",//可用余额
        submit:"Xác nhận đặt",//提交
        selection:"Đã lựa chọn",//当前选号
        perNote:"Nhập số tiền",//每注金额
        inputMoney:"Xin vui lòng nhập số tiền",//请输入金额
        total:"Tổng cộng",//总共
        note:"đã chọn",//注
        statistics:"Tổng giá trị ",//合计
        empty:"Xóa hết",//清空订单
        verify:"Xác nhận đặt",//确认提交
        issue:"Số kỳ",//期号
        num:"Số xổ số",//开奖号码
        big:"Lớn",//大
        small:"Nhỏ",//小
        single:"Đơn",//单
        double:"Đôi",//双
        unselected:"Không được chọn",//未选择
        drawLottery:"Xổ số thành công，Số kỳ：",//开奖成功，期号：
        kefu:"Xin vui lòng liên hệ với người quản trị để nhận Đặt cược!",//请联系管理员领取该任务!
    },
    mine:{
        recharge:"Rút tiền",//充值
        withdraw:"Liên kết ngân hàng",//提现
        myMoney:"Ví của tôi",//我的钱包
        detail:"Chi tiết",//详情
        balance:"Làm mới",//余额
        finance:"Bản báo cáo cá nhân",//个人报表
        order:"Chi tiết tài khoản",//账户明细
        record:"Hồ sơ Đặt cược",//任务记录
        my:"Trung tâm cá nhân",//个人中心
        notice:"Thông báo",//信息公告
        service:"Phản hồi",//在线客服
        score: "Điểm số",
    },
    setting:{
        title:"Thiết lập",//设置
        basic:"Thiết lập thông tin cơ bản",//基本信息设置
        password:"Mật khẩu đăng nhập",//登录密码
        capital:"Mật khẩu tài chính",//资金密码
        lang:"Ngôn ngữ",//语言
        quit:"Thoát khỏi đăng nhập",//退出登录
    },
    setPayPassword: {
        title:"Thiết lập mật khẩu tài chính",//设置资金密码
        opw:"Vui lòng nhập mật khẩu tài chính",//请输入资金密码
        tpw:"Xin vui lòng nhập mật khẩu tài chính một lần nữa",//请再次输入资金密码
    },
    gameRecord:{
        reject: "Từ chối",
        profit: "Lợi nhuận",
        place: "Đặt hàng",
        title:"Hồ sơ Đặt cược",//任务记录
        amountBet:"Giá trị",//下注金额
        create:"Thời gian duy nhất",//下单时间
        update:"Thời gian giải quyết",//结算时间
    },
    video:{
        title:"Rạp chiếu phim",//视频影院
        play:"chiếu",//播放
    },
    playVideo:{
        play: "Lần thứ hai",//次播放
    },
    setname:{
        title:"Sửa đổi tên thật",//修改真实姓名
        name:"tên",//姓名
        truename:"Xin nhập tên thật",//请输入真实姓名
        hint:"Để bảo mật tài khoản của bạn, tên thật cần phù hợp với tên thẻ ngân hàng ràng buộc",//为了您账户安全,真实姓名需要与绑定银行卡姓名一致
    },
    setsex:{
        title:"Sửa đổi giới tính",//性别修改
    },
    bindCard:{
        title:"Điền vào thẻ thanh toán",//填写收款卡
        msg:"Vui lòng nhập thông tin thẻ tín dụng của bạn",//请输入您的收款卡信息
        hint:"Người dùng thân mến, để bảo vệ tài chính của bạn, xin vui lòng liên kết tên thật của bạn và thiết lập mật khẩu rút tiền, nếu tên không phù hợp với tên mở tài khoản, sẽ không thể rút tiền",//尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款
        card: "Số tài khoản (thông thường)",
        truecard: "Nhập số tài khoản (thông thường)",
        name: "Tên tài khoản (bút danh)",
        truename: "Nhập tên tài khoản (bút danh)",
        financial_institution: "Tên tổ chức tài chính",
        truefinancial_institution: "Nhập tên tổ chức tài chính",
        branch: "Tên chi nhánh",
        truebranch: "Nhập tên chi nhánh",
        branch_number: "Số chi nhánh",
        truebranch_number: "Nhập số chi nhánh"
    },
    setbank:{
        title:"Thông tin thẻ thanh toán",//收款卡信息
        bank:"Thêm thẻ ngân hàng",//添加银行卡
        hint:"Gợi ý: hãy ràng buộc các ngân hàng thương mại lớn, nếu cần sửa đổi, xin vui lòng liên hệ với dịch vụ khách hàng trực tuyến",//提示:请绑定大型商业银行,如需修改,请您联系在线客服
    },
    setLoginPassword:{
        title:"Thay đổi mật khẩu đăng nhập",//修改登录密码
        old_password:"Mật khẩu cũ",//旧密码
        old_password_p:"Hãy nhập mật khẩu cũ",//请输入旧密码
        o_new_password:"Mật khẩu mới",//新密码
        o_new_password_p:"Hãy nhập mật khẩu mới",//请输入新密码
        t_new_password_p:"Xin vui lòng nhập mật khẩu mới một lần nữa",//请再次输入新密码
    },
    infomation:{
        title:"Thông tin cơ bản",//基本信息
        head:"Khuôn mặt",//头像
        select:"Chọn avatar",//选择头像
        name:"Tên thật",//真实姓名
        sex:"Giới tính",//性别
        man:"Người đàn ông",//男
        girl:"Phụ nữ",//女
        unknown:"Chưa được biết đến",//未知
        binding:"Thông tin ràng buộc",//绑定信息
    },
    withdraw: {
        title:"Trung tâm hiện tại",//提现中心
        record:"Hồ sơ rút tiền",//提现记录
        money:"Số tiền rút tiền",//提现金额
        all:"Tất cả",//全部
        min:"Giới hạn duy nhất: tối thiểu",//单笔限额：最低
        max:"Cao nhất",//最高
        num:"Số lượng rút tiền: ngày rút tiền cao nhất",//提现次数：一天最高提现
        hint:"Tài khoản thời gian: thời gian để tài khoản trong khoảng 5 phút, nhanh nhất trong vòng 2 phút",//到账时间：一般到账时间在5分钟左右，最快2分钟内到账
        quota:"Giới hạn mô tả",//限额说明
        balance:"Phần còn",//余额
        withdraw:"Ngay lập tức rút tiền",//马上提现
    },
    withdrawRecord:{
        desc:"Nghĩa là",//说明
        create:"Thời gian",//提交时间
        update:"Thời gian kiểm toán",//审核时间
    },
    personalreport:{
        title:"Bản báo cáo cá nhân",//个人报表
        profit:"Số tiền có lợi nhuận",//盈利金额
        formula:"Tính toán lợi nhuận: số tiền chiến thắng - số tiền Đặt cược",//盈利计算公式 : 中奖金额 - 任务金额
        task:"Số lượng Đặt cược",//任务金额
        recharge:"Nạp tiền",//充值金额
        withdrawal:"Số tiền rút tiền",//提现金额
        win_money:"Số tiền chiến thắng",//中奖金额
    },
    service: {
        title:"Dịch vụ khách hàng trực tuyến",//在线客服
        relation:"Liên lạc",//联系
        hint:"7 * 24 giờ để phục vụ bạn",//全天7 * 24小时竭诚为您服务
    },
    msg:{
        "次":"lần",
        "用户不存在！":"Người dùng không tồn tại!",//用户不存在！
        "密码错误！":"Mật khẩu sai!",
        "登录成功！":"Đăng nhập thành công!",
        "邀请码不存在！":"Mã mời không tồn tại!",
        "用户名位数错误！":"Sai số tên người dùng!",
        "密码位数错误！":"Mật khẩu số sai!",
        "用户名不能存在中文！":"Tên người dùng không thể tồn tại ở trung quốc!",
        "邀请码不能为空！":"Mã mời không phải là trống rỗng!",
        "注册成功！":"Đăng ký thành công!",
        "注册失败！":"Đăng ký thất bại!",
        "用户已存在":"Người dùng đã tồn tại",
        "刷新成功！":"Refresh thành công!",
        "请联系顾问或接待员":"Xin hãy liên lạc với tư vấn viên hoặc tiếp tân",
        "金额错误！":"Số tiền sai!",
        "请选号！":"Xin vui lòng chọn số!",
        "请填写金额!":"Xin vui lòng điền vào số tiền!",
        "余额不足，请联系客服充值！":"Số dư là không đủ, xin vui lòng liên hệ với dịch vụ khách hàng nạp tiền!",
        "没有更多了":"Không có nhiều hơn",
        "没有数据":"Không có dữ liệu",
        "余额不足！":"Số dư không đủ!",
        "扣款失败！":"Khấu trừ thất bại!",
        "下注异常！":"Đặt bất thường!",
        "投注成功！":"Đặt cược thành công!",
        "参数异常！":"Thông số bất thường!",
        "获取中":"Đang truy cập …",
        "请完成任务单后进入":"Hãy vào sau khi hoàn thành Đặt cược",
        "请联系客服充值":"Xin vui lòng liên hệ với phí dịch vụ khách hàng",
        "请设置收款卡!":"Xin vui lòng thiết lập thẻ thu ngân!",
        "功能已禁用!":"Chức năng đã bị vô hiệu hóa!",
        "账号下线":"Tài khoản dòng",
        "登录/注册":"Đăng nhập/đăng ký",
        "登录可享受更多服务！":"Đăng nhập có thể nhận được nhiều dịch vụ hơn!",
        "未设置":"Không được thiết lập",
        "已设置":"Đã thiết lập",
        "提交":"Được gửi đi.",
        "确定":"Chắc chắn",
        "请填写完整":"Xin vui lòng điền đầy đủ",
        "两次密码不一致！":"Hai mật khẩu không phù hợp!",
        "设置提现密码成功！":"Thiết lập mật khẩu rút tiền thành công!",
        "提现密码已设置，需要修改请联系客服":"Mật khẩu được thiết lập, bạn cần phải thay đổi xin vui lòng liên hệ với dịch vụ khách hàng",
        "已绑定":"Đã bị ràng buộc",
        "无":"Không có",
        "更换头像成功！":"Thay thế avatar thành công!",
        "更新头像失败！":"Cập nhật avatar thất bại!",
        "请勿重复设置！":"Đừng lặp lại thiết lập!",
        "设置姓名成功！":"Đặt tên thành công!",
        "设置姓名失败！":"Lỗi đặt tên!",
        "设置性别成功！":"Thiết lập thành công về giới tính!",
        "设置性别失败！":"Đặt thất bại về giới tính!",
        "请设置姓名后再绑定银行卡！":"Hãy đặt tên và sau đó ràng buộc thẻ ngân hàng!",
        "请设置提现密码后再绑定银行卡！":"Xin vui lòng thiết lập một mật khẩu rút tiền và sau đó ràng buộc thẻ ngân hàng!",
        "确认绑卡":"Xác nhận ràng buộc thẻ",
        "请输入银行卡号！":"Xin vui lòng nhập số thẻ ngân hàng!",
        "请输入银行名称！":"Xin vui lòng nhập tên ngân hàng!",
        "设置银行卡成功！":"Thiết lập thẻ ngân hàng thành công!",
        "设置银行卡失败！":"Thiết lập thẻ ngân hàng thất bại!",
        "旧密码错误":"Lỗi mật khẩu cũ",
        "修改密码成功！":"Sửa đổi mật khẩu thành công!",
        "修改密码失败！":"Sửa đổi mật khẩu thất bại!",
        "请填写正确的金额":"Xin vui lòng điền đúng số tiền",
        "提现金额不能少于：":"Rút tiền không thể ít hơn:",
        "提现金额不能大于：":"Số tiền rút tiền không thể lớn hơn:",
        "当日提现次数已用完":"Số lượng rút tiền trong ngày đã được sử dụng",
        "提现成功！":"Rút tiền thành công!",
        "提现失败！":"Rút tiền thất bại!",
        "金额":"Giá trị",
        "待审核":"Chờ xem xét",
        "审核成功":"Kiểm toán thành công",
        "审核退回":"Kiểm tra trở về",
        "请充值后观看视频！":"Xin vui lòng nạp tiền sau khi xem video!",
        "释放即可刷新":"Thả và làm mới...",
        "加载中":"Đang tải...",
        "下拉即可刷新": "Kéo xuống để làm mới...",
    },
    setLang:{
        title:"Thiết lập ngôn ngữ"
    },
    lang:{
        "zh": "Tiếng Trung Quốc",
        "jp": "Nhật Bản",
        "ara": "Ngôn ngữ",
        "en": "Tiếng Anh",
        "spa": "Tiếng Tây Ban Nha",
        "it": "Tiếng Ý",
        "de": "Tiếng Đức",
        "pt": "Tiếng Bồ Đào Nha",
        "fra": "Tiếng Pháp",
        "nl": "Tiếng Hà Lan",
        "id": "Tiếng Indonesia",
        "hi": "Tiếng Hindi",
        "kor": "Ngôn ngữ Hàn Quốc",
        "tr": "Tiếng Thổ Nhĩ Kỳ",
        "swe": "Tiếng Thụy Điển",
        "ga": "Tiếng Ireland",
        "ru": "Tiếng Nga",
        "vie": "Tiếng Việt",
        th: "Thái Lan",
        est: "Tiếng Estonia",
        be: "Tiếng Belarus",
        bul: "Tiếng Bulgaria",
        pl: "Tiếng Ba Lan",
        ms: "Tiếng Mã Lai",
    },
    profile: {
        portrait: "viết thật",
        video: "video",
        jianjie: "Hồ sơ cá nhân",
        liji: "Đặt lịch hẹn ngay",
        chongdan: "Phạm vi sạc",
        fd: "Quý bà",
        yuyue: "Hẹn trước",

        archives: "Lưu trữ cho cô gái",
        age: "Độ tuổi",
        height: "Chiều cao",
        weight: "Trọng lượng cơ thể",
        cup: "Chiếc cốc che mặt",
        city: "Thành phố",
        area: "Khu vực phục vụ",
        item: "Các dự án phục vụ"
    },
}
export default  Vie