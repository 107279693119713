<template>
  <div class="choose">
    <div  class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left">
          <span  style="font-size: 16PX; color: rgb(255, 255, 255); display: none;">届ける</span>
        </div>
        <div class="van-nav-bar__title van-ellipsis">
          <div  class="menu-item-icon van-image" style="height: 40PX; margin: 10PX 0PX 0PX;">
              {{ $t("choose.title")}}
          </div>
        </div>
        <div class="van-nav-bar__right">
         </div>
      </div>
    </div>
    <van-tabs v-model="active" animated swipeable  @change="OnChange" style="margin-top: 10PX">
      <van-tab v-for="(v,key) in addlist" :key="key" :title="v.name" :name="v.key" ></van-tab>
    </van-tabs>

    <div class="list" style="padding-bottom: 40.6150PX">
      <div class="pblBox">
        <div  style="width: calc(50% - 5PX);"  v-for="(v,key) in datalist" :key="key" @click="profile(v.id)">
          <div class="releaseItem">
            <div class="imgBox">
              <div class="van-image" style="width: 100%; height: 100%;">
                <img :src="v.img_url" class="van-image__img">
              </div>
              <span >
                  <i >{{$t("text.txt20")}}</i>
                </span>
              <div  class="infoBox">
                <i  class="van-icon van-icon-location" style="font-size: 14PX;"></i>
                <span style="font-size: 12PX; margin: 0PX 0PX 0PX 2PX;">{{ v.address }}</span>
                <div  class="wantyuebtn">{{ $t("profile.yuyue") }}</div>
              </div>
            </div>
            <div  class="tagBox" style="color: rgb(102, 102, 102);">{{ v.flag }}</div>
            <div  class="di">
              <div >
                <span  style="display: inline-block; margin-right: 2PX; width: 12PX; height: 12PX; border-radius: 50%; background: rgb(239, 186, 143);"></span>
                <span >{{ v.xuanfei_name }}</span></div>
              <div ><i  class="van-icon van-icon-like" style="color: rgb(220, 99, 161);"></i>
<!--                <span data-v-b46fb340="">{{ v.dianzan }}</span>-->
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="van-empty" style="width: 100%; padding-top: 90PX; color: rgb(0, 0, 0);" v-if="datalist.length == 0">
        <div class="van-empty__image" style="width: 50PX; height: 50PX;">
          <img src="/img/emptyData.png"></div>
        <p class="van-empty__description">{{ $t('msg.没有更多了') }}</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xuanfeiguanggao:'',
      active: 0,
      datalist:[],
      addlist: [],
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      baseInfo:[]
    };
  },
  methods: {
    getAddress() {
      this.$http({
        method: 'get',
        url: 'address_list'
      }).then(res => {
        this.addlist = res.data;
      });
    },

    profile(id) {
      this.$router.push({ path: '/profile?id=' + id});
    },
    OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表
    },

    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: {  id:this.active }
      }).then(res => {
        this.datalist = res.data;
      });
    },

    getChooseItem(){
      this.$http({
        method: 'get',
        url: 'base_choose'
      }).then(res=>{
        if(res.code == 200){
          this.xuanfeiguanggao = res.data.xuanfeiguanggao;
        }
      })
    },

    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{

        this.baseInfo =  res.data;
      })
    }
  },
  created() {
    this.getChooseItem ();
    this.getAddress();
    this.OnChange();
        this.getBaseInfo();
  }
};
</script>

<style lang="less" scoped>
releaseItem .tagBox {
  font-size:12PX;
  margin: 5PX 0;
}
.releaseItem .di {
  display: flex;
  justify-content: space-between;
  font-size:12PX;
  color: #666;
}
.releaseItem .imgBox .infoBox {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.4);
  display: flex;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 40PX;
  padding: 0 10PX;
}
.releaseItem .imgBox .infoBox .wantyuebtn {
  border: 1PX solid #fff;
  padding: 5PX 8PX;
  border-radius: 8PX;
  opacity: .9;
  font-size:12PX;
  margin-left: auto;
}
.releaseItem .imgBox>span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 80PX;
  height: 80PX;
  top: -40PX;
  right: -40PX;
}
.releaseItem .imgBox>span>i {
  position: absolute;
  bottom: 5PX;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size:12PX;
}
.van-image__img{
  display: block;
  width: 100%;
  height: 100%;
}
.van-image{
  position: relative;
  display: inline-block;
}
.imgBox{
  position: relative;
  overflow: hidden;
  border-radius: 8PX;
  height: 250PX;
}
.releaseItem{
  width: 100%;
  margin-bottom: 15PX;
}
.pblBox{
  display: flex;
  justify-content: space-between;
  padding: 10PX 20PX;
  flex-wrap: wrap;
}
.van-col{
  padding: 2.333vw;
  float: none;
}
.nav-bar {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100PX;
}
.van-nav-bar {
  line-height: 50PX;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35PX;
}
::v-deep .van-nav-bar__content {
  height: 100PX;
}
.van-sidebar {
  width: 140PX;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20PX;
  background: #f2f2f5;
}
.card {
  background-color: #8a637d;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 0.8125rem;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  height: 40PX;
  line-height: 40PX;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #0bdab0;
  font-size: 1.125rem;
}
.rig-content {
  font-size: 20PX;
  // margin-top: 10PX;
}
.address {
  width: 90%;
  margin: 0 auto;
}
</style>
