const Th = {
    text: {
        txt37: "บันทึกการลงคะแนน",
        choose: "การจับคู่",
        bond: "ได้รับการรับรองและจ่ายเงินมัดจำ $ 10,000",
        img: "การแสดงภาพ",
        intention: "経験満เท้า",
        casual: "คำแนะนำ สำหรับ Casual เพศ",
        txt1: "หมายเหตุ: แพลตฟอร์มนี้มีตะขอและห่วงการขนส่งทางอากาศของแท้มากที่สุด เพื่อปกป้องความเป็นส่วนตัวของผู้ใช้ สมาชิกจะต้องติดต่อฝ่ายบริการลูกค้าหรือแนะนำบุคคลที่ประสบความสำเร็จ",
        txt2: "ปรึกษาฝ่ายบริการลูกค้า",
        txt3: "ไกด์",
        txt4: "จดเบอร์ของน้องสาวและติดต่อฝ่ายบริการลูกค้า",
        ok: "โอเค",
        cancel: "การยกเลิก",
        txt5: "สำหรับสมาชิกทุกท่าน ในเว็บไซต์แสดงทรัพยากรเพียง 1% และอัปเดตก็ไม่สม่ำเสมอ ติดต่อฝ่ายบริการลูกค้าสำหรับแหล่งข้อมูลรายละเอียด",
        txt6: "ข้อมูลสมาชิกทั้งหมดบนแพลตฟอร์มเป็นความจริงและปลอดภัยและเชื่อถือได้ ใช้ได้ทั้งประเทศ หรือพบใหม่บนถนนสายเดียวกัน เลือกผู้หญิงที่ชอบ เจอกันจริงๆ เข้าร่วมตอนนี้ ~",
        txt7: "ข้อมูลสมาชิก",
        txt8: "การจับคู่",
        txt9: "กฎการใช้ประโยชน์",
        txt10: "ฉันต้องการเดทกับเธอ",
        txt11: "แพลตฟอร์มนี้ให้การค้นหาภาวะเงินฝืดที่แท้จริงที่สุด เพื่อปกป้องความเป็นส่วนตัวของข้อมูลส่วนบุคคลการลงทะเบียนจะต้องติดต่อฝ่ายสนับสนุนหรือยอมรับการแนะนำด้วยชื่อจริงของผู้ที่ประสบความสำเร็จในการค้นหาประสบการณ์ภาวะเงินฝืดเท่านั้นที่สามารถลงทะเบียนได้",
        txt12: "ปัจจุบันจับคู่ออนไลน์ 0 คู่",
        txt13: "44 เปิดใช้งาน",
        txt14: "สมาชิก 権li",
        txt15: "กิจกรรม",
        txt16: "วิดีโอของสมาชิก",
        txt17: "หลอกเขา",
        txt18: "ข้อมูลสมาชิก",
        txt19: "รหัสใหม่",
        txt20: "พบกัน",
        txt21: "สิ้นสุดการโหวต",
        txt22: "ระยะเวลา ผล",
        txt23: "ช่วงเวลา",
        txt24: "เลือกหมายเลข Pass",
        credit_score: "คะแนนเครดิต",
        mail: "จดหมายสถานี",
        txt25: "ต้องการ 15 คะแนนในการอัพเกรด",
        txt26: "คะแนนบุคคล",
        txt27: "จุด (8361;)",
        txt28: "การเติมเงิน",
        txt29: "จุดเปลี่ยน",
        txt30: "บริการออนไลน์",
        txt31: "บทนำ",
        txt32: "รายละเอียดจุด",
        txt33: "ประวัติการแลกเปลี่ยน",
        txt34: "การบันทึกข้อมูล",
        txt35: "เปลี่ยนรหัสผ่าน",
        txt36: "จุดเปลี่ยน",
    },
    login: {
        title: "ล็อกอิน",
        username: "กรุณาใส่ชื่อผู้ใช้",
        password: "กรุณาใส่รหัสผ่าน",
        forgetPaw: "ลืมรหัสผ่าน",
        registerNow: "ไม่มีบัญชี ลงทะเบียนตอนนี้",
        doLogin: "ล็อกอิน",

    },
    register:{
        title: "ลงทะเบียน",
        username: "กรุณาใส่ชื่อผู้ใช้ 6-12 ตัวอักษรภาษาอังกฤษหรือตัวเลข",
        password: "กรุณาใส่รหัสผ่านเข้าสู่ระบบ 6-12 ตัวอักษรภาษาอังกฤษหรือตัวเลข",
        code: "กรุณาป้อนรหัสเชิญ",
        agreement: "ฉันรู้และเห็นด้วยกับสนธิสัญญา ข้อตกลงการเปิดบัญชี แล้ว",
        chooseAgreement: "โปรดตรวจสอบข้อตกลงการเปิดบัญชีด้านล่าง",
        doRegister: "ลงทะเบียน",

    },
    footer:{
        home : "หน้าแรก",
        game: "นัดหมาย",
        video: "วีดีโอ",
        mine: "ของฉัน",

    },
    game:{
        title: "ห้องสำรองที่นั่ง",
        all: "ทั้งหมด",

    },
    choose:{
        title: "เลือกพระสนม",
        city: "สารานุกรมเมือง",
        rule: "เครือข่ายทั้งหมดของแพลตฟอร์มนี้เป็นจริงมากที่สุด + คุ้มกันธุรกิจ + ความหลงใหลในเมืองเดียวกันเพื่อให้มั่นใจในความเป็นส่วนตัวของผู้ใช้แต่ละคนลูกค้าจะถูก จำกัด ให้เข้าร่วมโดยติดต่อพนักงานต้อนรับหรือการแนะนำชื่อจริงของสมาชิกอาวุโสของแพลตฟอร์ม",
        price: "กระบวนการราคา",
        resource: "มีทรัพยากรอะไรบ้าง",
        character: "Net Red Model พนักงานต้อนรับบนเครื่องบิน นางแบบอ่อนโยน นักศึกษา มีเพียงคุณเท่านั้นที่ไม่สามารถคิดได้",
        scope: "ขอบเขตการให้บริการ",
        location: "ปืนฟรีในเมืองเดียวกันอากาศแห่งชาติในสถานที่ใด ๆ เมืองชั้นหนึ่งและชั้นสองในประเทศจีนมีอยู่ในท้องถิ่นและเมืองชั้นที่สามยังสามารถนัดหมายได้โดยติดต่อพนักงานต้อนรับ",

    },
    home:{
        recommend: "งานที่แนะนำ",
        more: "ดูเพิ่มเติม",
        popularity: "การจัดอันดับความนิยม",
        hot: "คำแนะนำยอดนิยม",

    },
    lottery:{
        jieguo: "ผลโหวต",
        jiesu: "สิ้นสุด",
        record: "บันทึกงาน",
        rule: "กฎการเล่น",
        prompt: "เคล็ดลับในการเล่น",
        gameplay: "เลือกหมายเลขจากรูปแบบตัวเลือกและค่าเพื่อเดิมพัน",
        explain: "คำแนะนำในการชนะ",
        wprole: "ผลรวมของหมายเลขลอตเตอรีทั้งสามคือ 11 ถึง 18 ค่ารวม 3 ถึง 10 มีขนาดเล็ก",
        example: "ตัวอย่างการเดิมพัน",
        option: "รูปแบบการเดิมพัน หมายเลขหวยเล็ก 123 นั่นคือขนาดเล็กและขนาดกลาง",
        task: "รายการงาน",
        money: "ยอดคงเหลือที่มีอยู่",
        submit: "ส่ง",
        selection: "หมายเลขการเลือกปัจจุบัน",
        perNote: "จำนวนต่อการเดิมพัน",
        inputMoney: "กรุณากรอกจำนวนเงิน",
        total: "รวมทั้งหมด",
        note: "รวมทั้งหมด",
        statistics: "รวม",
        empty: "ล้างออเดอร์",
        verify: "ยืนยันการส่ง",
        issue: "จำนวนงวด",
        num: "หมายเลขหวย",
        big: "ใหญ่",
        small: "เล็ก",
        single: "เดี่ยว",
        double: "คู่",
        unselected: "ยังไม่ได้เลือก",
        drawLottery: "ลอตเตอรีที่ประสบความสำเร็จหมายเลขงวด",
        kefu: "กรุณาติดต่อผู้ดูแลระบบสำหรับงานนี้",

    },
    mine:{
        recharge: "เติมเงิน",
        withdraw: "ถอนเงินสด",
        myMoney: "กระเป๋าตังค์ของฉัน",
        detail: "รายละเอียด",
        balance: "ยอดคงเหลือ",
        finance: "รายงานส่วนบุคคล",
        order: "รายละเอียดบัญชี",
        record: "บันทึกงาน",
        my: "ศูนย์บริการส่วนบุคคล",
        notice: "ประกาศข้อมูล",
        service: "บริการลูกค้าออนไลน์",
        score: "คะแนน",
    },
    setting:{
        title: "ตั้งค่า",
        basic: "การตั้งค่าข้อมูลพื้นฐาน",
        password: "รหัสผ่านเข้าสู่ระบบ",
        capital: "รหัสผ่านกองทุน",
        lang: "ภาษา",
        quit: "ออกจากระบบ เข้าสู่ระบบ",

    },
    setPayPassword: {
        title: "ตั้งรหัสผ่านกองทุน",
        opw: "กรุณาใส่รหัสผ่านกองทุน",
        tpw: "โปรดป้อนรหัสผ่านกองทุนอีกครั้ง",

    },
    gameRecord:{
        reject: "ยกเลิก",
        profit: "กำไร",
        place: "สั่งซื้อสินค้า",
        title: "บันทึกงาน",
        amountBet: "จำนวนเงินที่วางเดิมพัน",
        create: "เวลาสั่งซื้อ",
        update: "เวลาชำระบัญชี",

    },
    video:{
        title: "โรงภาพยนตร์วิดีโอ",
        play: "เล่น",

    },
    playVideo:{
        play: "การเล่นครั้งที่สอง",

    },
    setname:{
        title: "แก้ไขชื่อจริง",
        name: "ชื่อ",
        truename: "กรุณาใส่ชื่อจริงของคุณ",
        hint: "เพื่อความปลอดภัยของบัญชีของคุณชื่อจริงจะต้องตรงกับชื่อบัตรธนาคารที่ถูกผูกไว้",

    },
    setsex:{
        title: "การปรับเปลี่ยนเพศ",

    },
    bindCard:{
        title: "กรอกบัตรรับเงิน",
        msg: "โปรดกรอกข้อมูลบัตรชำระเงินของคุณ",
        hint: "เรียนผู้ใช้เพื่อความปลอดภัยของเงินของคุณโปรดผูกชื่อจริงของคุณและตั้งรหัสผ่านการถอนเงินหากชื่อไม่สอดคล้องกับชื่อบัญชีคุณจะไม่สามารถถอนเงินได้",
        card: "เลขที่บัญชี (ปกติ)",
        truecard: "ป้อนหมายเลขบัญชี (ปกติ)",
        name: "ชื่อบัญชี (นามแฝง)",
        truename: "ป้อนชื่อบัญชี (นามแฝง)",
        financial_institution: "ชื่อสถาบันการเงิน",
        truefinancial_institution: "ป้อนชื่อสถาบันการเงิน",
        branch: "ชื่อสาขา",
        truebranch: "ป้อนชื่อสาขา",
        branch_number: "หมายเลขสาขา",
        truebranch_number: "ป้อนหมายเลขสาขา"

    },
    setbank:{
        title: "ข้อมูลบัตรรับเงิน",
        bank: "เพิ่มบัตรธนาคาร",
        hint: "เคล็ดลับ โปรดผูกธนาคารพาณิชย์ขนาดใหญ่หากคุณต้องการแก้ไขโปรดติดต่อฝ่ายบริการลูกค้าออนไลน์",

    },
    setLoginPassword:{
        title: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
        old_password: "รหัสผ่านเก่า",
        old_password_p: "โปรดป้อนรหัสผ่านเก่า",
        o_new_password: "รหัสผ่านใหม่",
        o_new_password_p: "โปรดป้อนรหัสผ่านใหม่",
        t_new_password_p: "โปรดป้อนรหัสผ่านใหม่อีกครั้ง",

    },
    infomation:{
        title: "ข้อมูลพื้นฐาน",
        head: "อวตาร",
        select: "เลือก Avatar",
        name: "ชื่อจริง",
        sex: "เพศ",
        man: "ชาย",
        girl: "หญิง",
        unknown: "ไม่ทราบ",
        binding: "ข้อมูลที่ถูกผูกไว้",

    },
    withdraw: {
        title: "ศูนย์ถอนเงินสด",
        record: "บันทึกการถอนเงินสด",
        money: "จำนวนเงินที่ถอนออก",
        all: "ทั้งหมด",
        min: "ขีด จำกัด ปากกาเดี่ยว ต่ำสุด",
        max: "",
        num: "ถอนเงินสด ถอนเงินสดหนึ่งวัน",
        hint: "เวลามาถึง โดยปกติเวลามาถึงจะอยู่ที่ประมาณ 5 นาทีและเร็วที่สุดภายใน 2 นาที",
        quota: "คำอธิบายวงเงิน",
        balance: "ยอดคงเหลือ",
        withdraw: "ถอนเงินสดทันที",

    },
    withdrawRecord:{
        desc: "อธิบาย",
        create: "เวลาส่ง",
        update: "ระยะเวลาในการตรวจสอบ",

    },
    personalreport:{
        title: "รายงานส่วนบุคคล",
        profit: "จำนวนกำไร",
        formula: "สูตรการคำนวณกำไร จำนวนเงินที่ชนะ - จำนวนงาน",
        task: "จำนวนงาน",
        recharge: "จำนวนเงินที่เติม",
        withdrawal: "จำนวนเงินที่ถอนออก",
        win_money: "จำนวนเงินที่ชนะ",

    },
    service: {
        title: "บริการลูกค้าออนไลน์",
        relation: "ติดต่อ",
        hint: "7 * 24 ชั่วโมงตลอดทั้งวันเพื่อให้บริการคุณด้วยใจจริง",

    },
    profile: {
        portrait: "เขียนจริง",
        video: "วีดีโอ",
        jianjie:" โปรไฟล์ส่วนตัว",
        liji: "นัดหมายตอนนี้",
        chongdan: "ช่วงการชาร์จ",
        fd: "คุณผู้หญิง",
        yuyue: "นัดหมาย",

        archives: "ไฟล์น้องสาว",
        age: "อายุ",
        height: "ส่วนสูง",
        weight: "น้ำหนัก",
        cup: "ถ้วย",
        city: "เมือง",
        area: "พื้นที่ให้บริการ",
        item: "โครงการบริการ"
    },
    msg:{
        "次": "ครั้งที่สอง",
        "用户不存在！": "ไม่มีผู้ใช้",
        "密码错误！": "รหัสผ่านไม่ถูกต้อง",
        "登录成功！": "เข้าสู่ระบบเรียบร้อยแล้ว",
        "邀请码不存在！": "ไม่มีรหัสเชิญ",
        "用户名位数错误！": "จำนวนชื่อผู้ใช้ไม่ถูกต้อง",
        "密码位数错误！": "ตัวเลขรหัสผ่านไม่ถูกต้อง",
        "用户名不能存在中文！": "ชื่อผู้ใช้ไม่สามารถมีอยู่ในภาษาจีน",
        "邀请码不能为空！": "รหัสเชิญต้องไม่ว่างเปล่า",
        "注册成功！": "ลงทะเบียนเรียบร้อยแล้ว",
        "注册失败！": "ลงทะเบียนไม่สำเร็จ",
        "用户已存在": "มีผู้ใช้อยู่แล้ว",
        "刷新成功！": "รีเฟรชสำเร็จ",
        "请联系顾问或接待员": "กรุณาติดต่อที่ปรึกษาหรือพนักงานต้อนรับ",
        "金额错误！": "จำนวนเงินไม่ถูกต้อง",
        "请选号！": "กรุณาเลือกหมายเลข",
        "请填写金额!": "กรุณากรอกจำนวนเงิน",
        "余额不足，请联系客服充值！": "ยอดคงเหลือไม่เพียงพอโปรดติดต่อฝ่ายบริการลูกค้าเพื่อเติมเงิน",
        "没有更多了": "ไม่มีอีกแล้ว",
        "没有数据": "ไม่มีข้อมูล",
        "余额不足！": "ยอดคงเหลือไม่เพียงพอ",
        "扣款失败！": "การหักเงินล้มเหลว",
        "下注异常！": "เดิมพันผิดปกติ",
        "投注成功！": "เดิมพันสำเร็จ",
        "参数异常！": "พารามิเตอร์ผิดปกติ",
        "获取中": "รับ ...",
        "请完成任务单后进入": "โปรดทำรายการงานให้เสร็จ",
        "请联系客服充值": "กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อเติมเงิน",
        "请设置收款卡!": "โปรดตั้งค่าบัตรรับเงิน",
        "功能已禁用!": "คุณสมบัติถูกปิดใช้งาน",
        "账号下线": "หมายเลขบัญชีออฟไลน์",
        "登录/注册": "เข้าสู่ระบบ / ลงทะเบียน",
        "登录可享受更多服务！": "เข้าสู่ระบบเพื่อเพลิดเพลินกับบริการอื่น ๆ",
        "未设置": "ยังไม่ได้ตั้งค่า",
        "已设置": "ตั้งค่าแล้ว",
        "提交": "ส่ง",
        "确定": "แน่นอน",
        "请填写完整": "กรุณากรอกข้อมูลให้ครบถ้วน",
        "两次密码不一致！": "รหัสผ่านไม่สอดคล้องกันสองครั้ง",
        "设置提现密码成功！": "ตั้งรหัสผ่านถอนเงินสำเร็จแล้ว",
        "提现密码已设置，需要修改请联系客服": "มีการตั้งค่ารหัสผ่านการถอนเงินโปรดติดต่อฝ่ายบริการลูกค้าหากคุณต้องการแก้ไข",
        "已绑定": "ถูกผูกไว้แล้ว",
        "无": "ไม่มี",
        "更换头像成功！": "T ประสบความสำเร็จในการเปลี่ยนอวตาร",
        "更新头像失败！": "การอัพเดตอวาตาร์ล้มเหลว",
        "请勿重复设置！": "อย่าทำซ้ำการตั้งค่า",
        "设置姓名成功！": "การตั้งชื่อสำเร็จแล้ว",
        "设置姓名失败！": "การตั้งชื่อล้มเหลว",
        "设置性别成功！": "ตั้งค่าเพศที่ประสบความสำเร็จ",
        "设置性别失败！": "การตั้งค่าเพศล้มเหลว",
        "请设置姓名后再绑定银行卡！": "กรุณาตั้งค่าชื่อแล้วผูกบัตรของธนาคาร",
        "请设置提现密码后再绑定银行卡！": "กรุณาตั้งค่ารหัสผ่านสำหรับการใช้บัตรของธนาคารที่ถูกผูกไว้แล้ว",
        "确认绑卡": "ยืนยันการผูกบัตร",
        "请输入银行卡号！": "กรุณาใส่ธนาคาร",
        "请输入银行名称！": "กรุณาใส่ชื่อธนาคาร",
        "设置银行卡成功！": "ตั้งค่าบัตรธนาคารสำเร็จแล้ว",
        "设置银行卡失败！": "การตั้งค่าบัตรธนาคารล้มเหลว",
        "旧密码错误": "รหัสผ่านเก่าไม่ถูกต้อง",
        "修改密码成功！": "เปลี่ยนรหัสผ่านสำเร็จแล้ว",
        "修改密码失败！": "การเปลี่ยนรหัสผ่านล้มเหลว",
        "请填写正确的金额": "กรุณากรอกจำนวนเงินที่ถูกต้อง",
        "提现金额不能少于：": "จำนวนเงินที่ถอนได้จะต้องไม่น้อยกว่า",
        "提现金额不能大于：": "จำนวนเงินที่ถอนได้จะต้องไม่เกิน",
        "当日提现次数已用完": "การถอนเงินสดในวันนั้นหมดแล้ว",
        "提现成功！": "ถอนเงินสำเร็จ",
        "提现失败！": "การถอนเงินล้มเหลว",
        "金额": "จำนวนเงิน",
        "待审核": "รอการตรวจสอบ",
        "审核成功": "ตรวจสอบเรียบร้อยแล้ว",
        "审核退回": "ตรวจสอบและส่งคืน",
        "请充值后观看视频！": "กรุณาเติมเงินหลังจากดูวิดีโอ",
        "释放即可刷新": "ปล่อยเพื่อรีเฟรช ...",
        "下拉即可刷新": "ดึงลงเพื่อรีเฟรช ...",
        "加载中": "กำลังโหลด ...",

    },
    setLang:{
        title: "ตั้งค่าภาษา"
    },
    lang:{
        zh: "ภาษาจีน",
        jp: "ญี่ปุ่น",
        ara: "ภาษา",
        en: "ภาษาอังกฤษ",
        spa: "ภาษาสเปน",
        it: "ภาษาอิตาลี",
        de: "ภาษาเยอรมัน",
        pt: "ภาษาโปรตุเกส",
        fra: "ภาษาฝรั่งเศส",
        nl: "ดัตช์",
        id: "ภาษาอินโดนีเซีย",
        hi: "ภาษาฮินดี",
        kor: "ภาษาเกาหลี",
        tr: "ตุรกี",
        swe: "ภาษาสวีเดน",
        ga: "ภาษาไอริช",
        ru: "ภาษารัสเซีย",
        vie: "ภาษาเวียดนาม",
        th: "ภาษาไทย",
        est: "เอสโตเนีย",
        be: "ภาษาเบลารุส",
        bul: "บัลแกเรีย",
        pl: "โปแลนด์",
        ms: "ภาษามลายู",
    }
}
export default Th