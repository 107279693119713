<template>
  <div class="lottery">
    <div class="tops">
      <div class="van-nav-bar van-hairline--bottom">
        <div class="van-nav-bar__content">
          <div class="van-nav-bar__left"><i @click="back()" class="van-icon van-icon-arrow-left van-nav-bar__arrow">
            <!----></i></div>
          <div class="van-nav-bar__title van-ellipsis">{{ lottery.name }}</div>
        </div>
      </div>
      <div class="top">
        <div class="qs">
          <div><h1>{{ this.lottery.next_expect }}{{ $t("text.txt23") }}</h1></div>
          <span><span>{{ $t("text.txt21") }}</span> <van-count-down :time="time" @finish="check()"/></span></div>
        <div class="number">
          <div class="left">
            <div class="ball">
              <p>{{ this.lottery.now_expect }}<br>{{ $t("text.txt22") }}</p>
            </div>
          </div>
          <div class="right" @click="active = !active">
            <div class="res">
              <div><span>{{ sum }}</span><span>{{ this.size }}</span><span>{{ this.double }}</span></div>
              <div><span>{{ shanzi_1 }}</span><span>{{ shanzi_2 }}</span><span>{{ shanzi_3 }}</span></div>
            </div>
            <van-icon style="margin-left: auto;" name="arrow-up" v-if="active"/>
            <van-icon style="margin-left: auto;" name="arrow-down" v-if="!active"/>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <div class="history_popup"></div>
    <div class="wrapper">
      <div class="options-bar">
        <div class="game">
          <div class="linear-gradient"
               style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));"></div>
          <div class="sumValueTwoSides">
            <div class="rectangle large" :class="{active:choose[v.type]}" v-for="(v,key) in lottery_peilv_list"
                 :key="key" @click="choosePlay(v.type,v.name);">
              <p class="name-text large"><img style="width: 100%" :src="v.img"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bar">
          <div class="left">
            <div class="item" @click="shopping ? shopping = false : shopping = true ">
              <van-icon name="cart-o" class="jixuanico"/>
            </div>
            <div class="line"></div>
          </div>
          <div class="mid">
            <span class="text">{{ $t("lottery.money") }}</span>
            <span class="text num">{{ this.userInfo.money }}</span>
            <span class="text"></span>
          </div>
          <div class="right" @click="jiesuan()">
            {{ $t("lottery.submit") }}
          </div>
        </div>
        <div class="wrapper" :class="{active:shopping}">
          <div class="item">
            <span class="label">{{ $t("lottery.selection") }}：</span>
            <div class="bet-number">{{ this.shopchoose }}</div>
            <van-icon name="arrow-down" :class="{ up: !shopping,down:shopping }"
                      @click="shopping ? shopping = false : shopping = true"/>
          </div>
          <div class="item">
            <span class="label">{{ $t("lottery.perNote") }}：</span>
            <div class="amount-wrapper">
              <van-field v-model="money" style="background: rgb(242, 242, 245);" type="digit"
                         :placeholder="$t('lottery.inputMoney')"/>
              <span class="label"></span>
            </div>
          </div>
          <div class="item">
            <div class="part">
              <span>{{ $t("lottery.total") }}</span>
              <span class="number">{{ this.formData.length }}</span>
              <span>{{ $t("lottery.note") }}</span>
            </div>
            <div class="part">
              <span>{{ $t("msg.金额") }}</span>
              <span class="number">{{ this.formData.length * this.money }}</span>
              <span>{{ $t("lottery.statistics") }}</span>
            </div>
          </div>
          <div class="jifenItemBox">
            <div v-for="(vo,keys) in betting_amount" :key="keys" :class="money==vo?'active':''" @click="moneyFun(vo)">
              {{ formatNumber(vo) }}
            </div>
          </div>
        </div>
      </div>
      <van-popup v-model="jiesuanpage" get-container="body">
        <div class="confirm-order-modal">
          <div class="head van-hairline--bottom">
            <p class="text">{{ $t("lottery.task") }}</p>
          </div>
          <ui class="list">
            <li v-for="(v,key) in formData" :key="key" class="lise-item van-hairline--bottom">
              <div class="main">
                <p class="bet-name">{{ names(v.name) }}</p>
                <p class="detail-text">1{{ $t("lottery.note") }}X{{ money }}={{ money }}</p>
              </div>
              <van-icon @click="clearChooes(v.type)" style="font-size: 22PX" name="close"/>
            </li>
          </ui>
          <div class="sub-bar">
            <van-button class="item cancel-btn" type="default" @click="allClear()">{{
                $t("lottery.empty")
              }}
            </van-button>
            <van-button class="item sub-btn" type="default" @click="doSub">{{ $t("lottery.verify") }}</van-button>
          </div>
        </div>
      </van-popup>
      <div class="history" v-if="active">
        <div class="animate__animated animate__fadeInDown">
          <div class="item">
            <div class="left">{{ $t("text.txt23") }}</div>
            <div class="right">{{ $t("text.txt24") }}</div>
          </div>
          <div class="item"  v-for="(v,key) in lottery_list" :key="key">
            <div class="left">{{ v.expect }}</div>
            <div class="right">
              <div><span>{{ v.opencode[0] }}</span><span>{{ v.opencode[1] }}</span><span>{{ v.opencode[2] }}</span></div>
              <div>
                <span>{{ v.opencode[0] + v.opencode[1] + v.opencode[2] }}</span>
                <span>{{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t("lottery.big") : $t("lottery.small")  }}</span>
                <span>{{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("lottery.double") : $t("lottery.single")  }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
var time;
var count = 0;
export default {
  data() {
    return {
      jiesuanpage: false,
      choose: {
        "大": false,
        "小": false,
        "单": false,
        "双": false,
      },
      playgame: false,
      shopping: false,
      isLoading: false,
      play: {},
      lottery_peilv_list: {},
      lottery_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      shanzi_1: "0",
      shanzi_2: "0",
      shanzi_3: "0",
      sum: 0,
      size: "",
      double: "",
      time: 0,
      shopchoose: this.$t("lottery.unselected"),
      gameitem: "",
      formData: [],
      betting_amount: [],
      money: "",
    };
  },
  methods: {

    formatNumber(num) {
      if (num >= 1000 && num < 10000) {
        return (num / 1000).toFixed(0) + 'K';
      } else if (num >= 10000) {
        return (num / 10000).toFixed(0) + 'W';
      } else {
        return num.toString();
      }
    },
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        console.log(res.data.betting_amount);
        this.betting_amount = res.data.betting_amount;
      })
    },
    back() {
      return window.history.back();
    },
    moneyFun(money) {
      this.money = money
    },
    names(name) {
      if (name == "大") {
        return this.$t("lottery.big");
      } else if (name == "小") {
        return this.$t("lottery.small");
      } else if (name == "单") {
        return this.$t("lottery.single");
      } else if (name == "双") {
        return this.$t("lottery.double");
      }
      return name;
    },
    doSub() {
      if (this.money == "0") {
        this.$toast(this.$t("msg.金额错误！"));
        return false;
      }
      if (this.formData.length == 0) {
        this.$toast(this.$t("msg.请选号！"));
        return false;
      } else if (this.money == "") {
        this.$toast(this.$t("msg.请填写金额!"));
        return false;
      } else {
        if (this.userInfo.money - (this.money * this.formData.length) < 0) {
          this.$toast(this.$t("msg.余额不足，请联系客服充值！"));
          return false;
        } else {
          this.$http({
            method: 'get',
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect
            },
            url: 'game_place_order'
          }).then(res => {
            if (res.code === 200) {
              this.$toast(this.$t("msg." + res.msg));
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(this.$t("msg." + res.msg));
            }
          })
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]['type']] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = this.$t("lottery.unselected");
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
    },
    jiesuan() {
      if (this.formData.length == 0) {
        this.$toast(this.$t("msg.请选号！"));
        return false;
      } else if (this.money == "") {
        this.$toast(this.$t("msg.请填写金额!"));
        return false;
      } else if (this.money == 0) {
        this.$toast(this.$t("msg.金额错误！"));
        return false;
      } else {
        this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
      }

    },
    clearChooes(type) {
      for (var i = 0; i < this.formData.length; i++) {
        if (type === this.formData[i]['type']) {
          this.formData.splice(i, 1)
          this.choose[type] = false;
        }
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.names(this.formData[j]['name']);
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.names(this.formData[j]['name']);
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t("lottery.unselected");
        this.gameitem = "";
        this.shopping = false;
      }
      if (this.formData.length === 0) {
        this.jiesuanpage = false;
      }
    },
    choosePlay(type, name) {
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]['type']) {
            this.formData.splice(i, 1)
          }
        }
      } else if (this.choose[type] === false) {
        this.formData.push({'name': name, 'type': type})
        this.choose[type] = true;
      }
      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.names(this.formData[j]['name']);
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.names(this.formData[j]['name']);
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t("lottery.unselected");
        this.gameitem = "";
        this.shopping = false;
      }

    },
    check() {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        time = window.setInterval(() => {
          setTimeout(() => {
            this.getUserInfo();
            this.getLotteryInfo();
            this.getLotteryList();
            count++;
            if (count > 5) {
              clearInterval(time);
              count = 0;
            }
          }, 0)
        }, 300)
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("msg.刷新成功！"));
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryPeilv() {
      this.$http({
        method: 'get',
        data: {id: this.$route.query.id},
        url: 'lottery_get_peilv'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryList() {
      this.$http({
        method: 'get',
        data: {key: this.$route.query.key},
        url: 'lottery_get_one_list'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryInfo() {
      this.$http({
        method: 'get',
        data: {key: this.$route.query.key},
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
            this.$toast(this.$t("lottery.kefu"));
            this.$router.push({path: '/Home'})
            return false;
          }
          this.lottery = res.data;
          this.time = res.data.second * 1000;

          if (this.time / 1000 === 59) {
            this.$toast(this.$t("lottery.drawLottery") + this.lottery.now_expect);
          }
          this.shanzi_1 = res.data.opencode[0];
          this.shanzi_2 = res.data.opencode[1];
          this.shanzi_3 = res.data.opencode[2];
          this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
          if (this.sum >= 11 && this.sum <= 18) {
            this.size = this.$t("lottery.big");
          } else if (this.sum >= 3 && this.sum <= 10) {
            this.size = this.$t("lottery.small");
          }
          if (this.sum % 2 === 0) {
            this.double = this.$t("lottery.double");
          } else {
            this.double = this.$t("lottery.single");
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })

    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({path: '/Login'})
    } else {
      this.getUserInfo();
      this.getLotteryInfo();
      this.getLotteryList();
      this.getBaseInfo();

    }
  },
  destroyed() {
    clearInterval(time);
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.lottery .history{
  top: 0;
  height: calc(100% - 0.33333rem);
  z-index: 3;
}

.sumValueTwoSides .rectangle.active {
  border: .33333rem solid #f8596f;
  background: url(/img/lottery/select.png) no-repeat 101% 100% / 1.33333rem;
}
.wrapper{
  position: relative;
  flex: 1;
  overflow: hidden;
  width: 100%;
}
.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 11vw);
  background: #f2f3f5;
}
.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.options-bar .game .tips{
  display: flex;
  align-items: center;
  height: 20PX;
  padding: 0 20PX;
}
.options-bar .game .tips .odds{
  flex: 1;
  font-size: 18PX;
  font-weight: 500;
  color: #ff253f;
}
.options-bar .game .tips .play-tip{
  display: flex;
  align-items: center;
  height: 100%;
}
::v-deep .van-icon-more-o{
  color: #ff253f;
  font-size: 20PX;
}
.options-bar .game .tips .play-tip .span-text{
  margin-left: 10PX;
  font-size: 18PX;
  font-weight: bolder;
  color: #ff253f;
}
.linear-gradient{
  width: 100%;
  height: 2PX;
}
.sumValueTwoSides{
  display: flex;
  padding: 16PX 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 200PX;
}
.sumValueTwoSides>div {
  width: calc(50% - 7vw)!important;
  height: 7.66667rem;
  text-align: center;
  background: #fff;
  box-shadow: 0 2PX 12PX 0 rgba(0,0,0,.1);
}
.rectangle{
  overflow: hidden;
}
.rectangle.large{
  margin: 0 0 16PX 4%;
  width: 20%;
  border-radius: 10PX;
}
.rectangle .wrapper{
  position: relative;
  padding: 0 10PX;
  background: #fff;
}
.rectangle .wrapper .content{
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.rectangle.large .wrapper{
  padding-bottom: 100%;
}
.rectangle .wrapper .content .name-text.large{
  font-size: 25PX;
}
.rectangle .wrapper .content .name-text{
  color: #7d7c7c;
  font-weight: bolder;
}
.rectangle .wrapper .content .odd-text.large{
  font-size: 25PX;
  margin-top: -16PX;
}
.rectangle .wrapper .content .odd-text{
  text-align: center;
  color: #ff253f;
}
.bottom-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60PX;
  z-index: 2;
}
.bottom-bar .bar .left, .bottom-bar .bar{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottom-bar .bar{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60PX;
  background-color: #fff;
  box-shadow: 0 0 20PX 0 #cacaca;
  z-index: 2;
}
.bottom-bar .bar .left, .bottom-bar .bar{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottom-bar .bar .left .item{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20PX;
  font-size: 20PX;
}
.bottom-bar .bar .left .item .text{
  font-size: 22PX;
  color: #7d7c7c;
}
.jixuanico{
  font-size: 25PX;
  margin-left: 20PX;
}
.bottom-bar .bar .left .line{
  width: 2PX;
  height: 20PX;
  background: #dadada;
}
.bottom-bar .bar .mid{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom-bar .bar .mid .text{
  font-size: 16PX;
  font-weight: 500;
  color: #000;
}
.bottom-bar .bar .mid .text.num{
  margin: 0 5PX;
  color: #ff253f;
}
.bottom-bar .bar .right{
  padding: 0 16PX;
  margin: 0 16PX;
  color: #fff;
  background: #f6287f;
  font-size: 18PX;
  font-weight: 500;
  height: 55PX;
  line-height: 55PX;
  border-radius: 10PX;
}
.rectangle.active .wrapper{
  background-color: #ff253f!important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 18PX;
}
::v-deep   .van-popup {
  position: absolute;
}
::v-deep .van-overlay {
  position: absolute;
  background-color: rgb(70 67 67 / 70%);
}
::v-deep  .van-popup--top {
  top: -1PX;
}
.wrapper .item{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10PX 0;
}
.wrapper .item .left{
  width: 40%;
  font-size: 16PX;
  text-align: center;
  font-weight: 500;
  color: #000;
}
.font-weight{
  font-weight: 700!important;
}
.wrapper .item .right{
  flex: 1;
  display: flex;
  font-size: 16PX;
  justify-content: center;
  overflow: hidden;
  color: #000;
}
.wrapper .item .kuaisan-ball .left{
  justify-content: flex-start;
}
.wrapper .item .kuaisan-ball{
  margin-left: 20PX;
  flex: 1;
  display: flex;
  align-items: center;
}
.wrapper .item .kuaisan-ball .res-img{
  width: 20PX;
  height: 20PX;
  margin-right: 20PX;
}
.wrapper .item .kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #000;
}
.wrapper .item .kuaisan-ball .res-des.middle{
  width: 15%;
  font-size: 18PX;
}
.play-type-tip{
  position: unset;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 620PX;
  height: 700PX;
  max-height: 50%;
  z-index: 10;
  border-radius: 16PX;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}
.play-type-tip .title{
  line-height: 90PX;
  background: linear-gradient(
      90deg,#7e5678,#e6c3a1);
  text-align: center;
  color: #fff;
  font-size: 18PX;
  font-weight: 500;
}
.mask{
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}
.play-type-tip .wrapper{
  height: calc(100% - 10PX);
  background-color: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.play-type-tip .wrapper .item{
  padding: 18PX 20PX;
  display: flex;
  align-items: flex-start;
}
.play-type-tip .wrapper .item .van-icon{
  color: #e6c3a1;
  font-size: 60PX;
}
.play-type-tip .wrapper .item .content .content-title{
  margin-top: 22PX;
  font-size: 18PX;
  font-weight: 500;
  color: #000;
  
}
.play-type-tip .wrapper .item .content .content-detail{
  margin-top: 5PX;
  font-size: 22PX;
  color: #000;
  line-height: 16PX;
}
.play-type-tip .wrapper .item .content{
  flex: 1;
  margin-left: 16PX;
}
.rectangle.active .wrapper{
  background-color: #ff253f!important;
}
.rectangle.active .wrapper .name-text, .rectangle.active .wrapper .odd-text{
  color: #fff!important;
}
.bottom-bar .wrapper{
  position: absolute;
  top: 10PX;
  left: 0;
  right: 0;
  padding: 20PX 20PX 10PX 20PX;
  height: 160PX;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10PX 0 #cacaca;
  transition: transform .3s cubic-bezier(.21,1.02,.55,1.01);
}
.jifenItemBox{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 4vw 0 0;
}
.jifenItemBox>div {
  color: #a063ee;
  width: 17vw;
  height: 12vw;
  line-height: 11vw;
  text-align: center;
  background: url(/img/hua.png) no-repeat 50%;
  background-size: contain;
  font-size: 3vw;
}
.jifenItemBox>div.active {
  color: #fff;
  background: url(/img/hua_H.png) no-repeat 50%;
  background-size: contain;
}
.bottom-bar .wrapper.active{
  transform: translateY(-100%);
}
.bottom-bar .wrapper .item{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40PX;
}
.bottom-bar .wrapper .item .label{
  font-size: 16PX;
  line-height: 16PX;
  color: #000;
}
.bottom-bar .wrapper .item .bet-number{
  flex: 1;
  margin: 0 16PX;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  color: #ff253f;
  font-size: 16PX;
  font-weight: 500;
  height: 18PX;
  line-height: 18PX;
}
.bottom-bar .wrapper .item .amount-wrapper{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.van-cell {
  font-size: 16PX;
  line-height: 20PX;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10PX 16PX;
  overflow: hidden;
  color: #323233;
  background-color: #fff;
}
.bottom-bar .wrapper .item .part{
  margin-right: 20PX;
}
.bottom-bar .wrapper .item .part span{
  font-size: 16PX;
  vertical-align: center;
  color: #000;
}
.bottom-bar .wrapper .item .part .number{
  margin: 0 5PX;
  color: #ff253f;
  font-weight: 500;
}
::v-deep .van-field__control {
  color: #ff253f;
}
.confirm-order-modal{
  position: unset;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 0 16PX;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 305PX;
  height: 360PX;
  max-height: 50%;
  z-index: 10;
  background-color: #fff;
  border-radius: 16PX;
}
.sumValueTwoSides>div p {
  margin: 0;
}
.confirm-order-modal .head{
  position: relative;
  height: 40PX;
}
.confirm-order-modal .head .text{
  padding: 0 10PX;
  height: 40PX;
  line-height: 40PX;
  text-align: center;
  font-size: 18PX;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(133deg, #e64588, #f22678);;
}
::v-deep .confirm-order-modal .van-hairline--bottom::after {
  border-bottom-width: 2PX;
}
.van-popup--center {
  border-radius: 16PX;
}
.confirm-order-modal .list{
  flex: 1;
  padding: 0 16PX;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.confirm-order-modal .list .lise-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10PX 0;

}
.confirm-order-modal .list .lise-item .main{
  flex: 1;
  overflow: hidden;
}
.confirm-order-modal .list .lise-item .main .bet-name{
  color: #ff253f;
  font-size: 18PX;
  font-weight: 500;
  word-wrap: break-word;
  word-break: break-all;
}
.confirm-order-modal .list .lise-item .main  .detail-text{
  
  font-size: 14PX;
  color: #979799;
}
.confirm-order-modal .list .lise-item{
  color: #ff253f;
}
.confirm-order-modal .sub-bar{
  display: flex;
  align-items: center;
  margin-top: 16PX;
  justify-content: space-around;
}
.confirm-order-modal .sub-bar .item{
  min-width: 40%;
  height: 40PX;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10PX;
  font-size: 18PX;
  font-weight: 500;
}
.confirm-order-modal .sub-bar .item.cancel-btn{
  border: 2PX solid #979799;
  color: #979799;
  background-color: #fff;
}
.confirm-order-modal .sub-bar .item.sub-btn{
  background: #f6287f;
  color: #fff;
  border: 0;
}
.next-number span{
  font-size: 18PX;
  font-weight: 700;
  color: #000;
  float: right;
}
</style>
