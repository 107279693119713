<template>
  <div class="bg">
    <div class="register">
      <div class="head" style="z-index: 3;position: relative;"><i class="van-badge__wrapper van-icon van-icon-close"  @click="back()"></i><a @click="toLogin()">{{ $t("login.doLogin") }}</a></div>

      <img  :src="this.$store.getters.getBaseInfo.login_ico !==undefined ?this.$store.getters.getBaseInfo.login_ico:'/img/null.png'">
      <form style=" z-index: 3;   position: relative;">
        <div class="ipt"><i class="van-badge__wrapper van-icon" style="color: rgb(153, 153, 153); font-size: 20px;"><img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAw9JREFUOE+V01toE1kcx/HvmSSN0WrVtK7WBVeNvtRWKypIBS/1Qenuy0K01suLVMVbJRW1ijXbCNJ6w1tFV3ZVUNRQBUXwUiuiFsGKom1fvCAIjbcg8ZaaJnPkzHSaRgPL/l9mzgznM7//OWcEgAShrv+3hDE1WeIn6Nw5jV+nZjAxZzgO5xJi8UZq7zTjv6WDP2WyxVhoL8xvppPbNgILkfI9unyGTRsJuIxxOLqcnJ1v0qEK7Ma6oedr+zNyYDOJRCVlZddT2q6v/4Xs7GvMC44n2N6dMDWpkPi1nkmdmzw4nQeY+VdJ2vW7uS3E48e/sX17J8E8ifq4W9NIJBK43Z+SmLdNcPz4eFyuaiav8KbF7h8JcffJGPYHPhrvz569ixBZQCdCTDQxBb3LEzRu/RtEJgU1C9NirdXtSHma4kCN8T6nzWxXpQSE9HptxgOFXdlSQSR6j6ItLemT1Zbg0L8w3ddE/1wT6gWamIJUXa76Hbs2iTxfgLg79ezlD9O4UH6KV6+rKDn6AleHTAGDeTKJfeoQDBmncWnVSS62rsF3wVwXVcMighs7lqGLd0xY30CGW/Jg83zsYhSaphJ+oLS0PhWL5goa1+UzdMABiiqLU1pt3hPm8OXh1LVEsYcl/6wdQF+HHZeUOF06VWvCQs7w241JKlnEqRnttfjmMDiziqLKGRR6bBxa2cGdpxPYcDhEKEsamCqV0Go3p02amIJUqlhYGFjrxgXYtLHM2mTu2u3dTbx8W87i2ufG2AItTD0b9UFPYvs3DGLSiJU4bH8Si1+nfF817V/MBEuLnZRPPYamjeBr7BiBq6c43xj7MZ2Ql1r6MrfwIJoYQiR6lKehh1Qcek8sK7mbXZ/N+xMVuYzOnkY/52q+JU5T4DvIozovfTJKmB1YLGQ8cZKu+BXy1zcYLaqd6w0pxMIcmWbSPzx2NpfuIha7yZS6BmvdhNT1JsbVzOlZr//CMiIm6CnU+HfRCQpqypJYV9dehM2D9bvruqBn0H04pC4QxnkCdPOiztfXb2eYUhu0sO93BVYkZoYTLAAAAABJRU5ErkJggg=="
            class="van-icon__image"></i><input  v-model="username" :placeholder="$t('register.username')" type="text"></div>
        <div class="ipt"><i class="van-badge__wrapper van-icon" style="font-size: 20px;"><img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAARCAYAAADtyJ2fAAAAAXNSR0IArs4c6QAAAoxJREFUOE91k19IFFEUxr87M+66jlqkCxEVST0EQpgGuSC4RWYlUoorpYX20kOUIYhLWbJIliFWGEXUiyKBqGVimFmRkPknCSswQmlJol62zNx13V1n5sSdcddUui/3D+d3vu/cey4jgCE8HG0CHhzZBUm6BMa2ARBA9BkB9RpKOofRXqiFQ5kBugx4vsqOaKkRoVAVPJ4B/cy6PgMmsQYBpRyW2n4DdBEjuAR9/eVEHJKSniOgnsOh2tGIC77oqkxDXHQjpqaykdQ8y48M0DHO0NS0ExbLTdQ070P3kLoMzLWJqC59gYDiREnRMNqTiZHDIcKTzPDEmYlocyV21OQi9Gupbp7BlED4WN2NYOgWcup6YR0nRnaXBO8PhpfXMxEvV+DwvfxlauFN16lH8PlvY095L+I2EKNXY2uRnpyHKCELgmCHovXxEkCaocoE4ncLQdgPYABB5RnGJjsZaVoPNPqJuWAPCATGRKgqA8RFLRUQRQKRCgYGiykbgrCJg250vN+NGy0+hNYwLPh0JY8Sq89WyUd6hqhYgukP4WyxjGNpI4w0cuPxYDrqO7xhkEPr+soyuYPfR++/TvR6tAh4oVhGTuqQAbaO2NDQ6+VqHLLWFybgwPZPek/1T6R6zrR+15W5qnOvjIKMwSWwrsMXtme2bTTF3y19CAbR62zLDzydCERsV+TJOG57swpkql+vLfHD5QYQTNP2K2XajJ9IjCFd9X+g/gKqnyWOuE5DENl01tU7qhd6c68ANTda39rwj1UORqVtkXjwwruvCleLgBeLYlCQwmvUJvFt5iR6RifmJNF49FBwRcuZdVBWVMLBlK3YbG1hNDtfAtl8HuAfbHn8qtajxZj5YMNfzQA5+DtACFwAAAAASUVORK5CYII="
            class="van-icon__image"></i><input v-model="password"  :placeholder="$t('register.password')"
                                               :type="passwordType" autocomplete="">
          <van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/></div>
        <div class="ipt"><i class="van-badge__wrapper van-icon" style="font-size: 20px;"><img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAAAAXNSR0IArs4c6QAAAuVJREFUOE91k1toVEcYx//fnHP2GrMmMbKx+tZKILTBpK02IogBfbBoq6zVICqFIvEhMWmjNWrdWsUYUVHwgi9KUR9cCGgpPlQx4jX1VsUQ8BLJxaRNYrKbbHJyzs7OV87ZWKHqvMx/vvnmN/9vLsQAAVHC6xZpfaP/C06IWBG/CUVdTYyowGh9KQLGn//Pf+9YyjIYu1sygEhEw6kzJQgYV9GbLAWBYNsZFz4jhPFUwtUeD4MtRkHeTZhyMdZWtCBWxMTzozrObypBtv8KPqrKdZObfvgAx1v6cCQygs0XsjEn7MGm00l37smhXsSTS7DswG3ktzLxpbY8zJrxKXKDTSirnQI7RLix7RB0MQMaLYRlN0I3ylFzthx376VwY/9LxM01eNp5CxsODhMzWwAMACaK66b2yyzSiwq00Mm1O0XQWwXG37j4YE5/3YVX+XqS8VdjF4hyAEj0xD93ADbG7J8R8NQPFG4Oez4JG9lnq35TlmwRQe/GdPfAai2csy7x1b5vUs+G5JS2vR0w7QPwG/UYHP3SAaQBKDCnrXO3isyGP155F36cJdtejE+KVZ9Ifn1sfbq7R7LmY1/l3Gzfd+WPQRRwz+OfxCJipcbk485yvXB6IwytGENjDcPz9h2noEVIewmaxTzq5azm2m8pL2srpdUT+aKvTv8w3IT+4ZUOwBz6bEtYnxzSA79WLtXCoV0Am+n2vh/NFYeb/acrv9BmTm9gwfk0MLJ9pOJoTMYTMudOQ7vsHcwA4iU7C0QKwtlVn1vo8/4UqRUhfzXb8jp59Xkqbh61fjnfKK8/GnPcKANq8v0dz/EakCiunyb8JBzLpDLWPd8vCHuWz65JXW49YkVjHU7JLLzslKRMVqGHe57J7sFVroO3AE62C7OJhcdd9E5AR1+F+w7UwMhWAZBSzscChHMtE9odC7Ca6N3TF0IhNxiVXYPLiVPydwBBdwdmev9XBBwbRJRxo3jcvta24V9d9Gm+o8lOZQAAAABJRU5ErkJggg=="
            class="van-icon__image"></i><input v-model="code"  :placeholder="$t('register.code')"
                                               type="text"></div>
      </form>
      <div class="res">
        <div class="agreement">
          <van-checkbox v-model="checked" />
          <span class="agreement-text">{{ $t("register.agreement") }}</span>
        </div>
      </div>
      <div class="btn active" style="position: relative;
    z-index: 99;" @click="doRegister">{{ $t("register.doRegister") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: '',
      password: this.inputValue,
      passwordType: 'password',
    };
  },
  methods: {
    toLogin() {
      this.$router.push("Login")
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back() {
      this.$router.push("/")
    },
    doRegister() {
      if (this.username === "" || this.username === null || this.username === undefined) {
        console.log(this.$t('login.username'));
        this.$toast.fail(this.$t('login.username'));
        return false;
      }
      if (this.password === "" || this.password === null || this.password === undefined) {
        this.$toast.fail(this.$t('login.password'));
        return false;
      }
      if (this.code === "" || this.code === null || this.code === undefined) {
        this.$toast.fail(this.$t('register.code'));
        return false;
      }
      if (!this.checked) {
        this.$toast.fail(this.$t('register.chooseAgreement'));
        return false;
      }
      this.$http({
        method: 'get',
        data: {
          username: this.username,
          password: this.password,
          code: this.code
        },
        url: 'member_register'
      }).then(res => {
        if (res.code === 200) {
          this.$toast.success(this.$t("msg." + res.msg));
          localStorage.setItem('token', res.data)
          this.$router.push("Mine")
        } else {
          this.$toast.fail(this.$t("msg." + res.msg));
        }
      })
    }
  },
  created() {
    if (localStorage.getItem('token')) {
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.agreement{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement .agreement-text{
  margin-left: 10px;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  line-height: 30px;
}
::v-deep .agreement .van-icon {
  font-size: 30px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 38px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(244, 49, 127);
  background-color: rgb(244, 49, 127);
}
</style>
