<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('service.title')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="servicelistItem">
        <div class="servicelistItemTop">
          <img class="servicelistItemImage" src="img/mine/kefu.png">
          <div class="servicelistItemText">{{this.$store.getters.getBaseInfo.name !==undefined ?this.$store.getters.getBaseInfo.name:''}}</div>
          <div class="servicelistItemBtn" @click="toServicePage()">
            <div class="servicelistItemBtnText">
              {{ $t("service.relation") }}
            </div>
          </div>
        </div>
        <div class="servicelistItemBottom">
        <div class="servicelistItemInfoText">

          {{ $t("service.hint") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    toServicePage(){
      window.location.href = this.$store.getters.getBaseInfo.kefu;
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.servicelistItem{
  display: flex;
  flex-direction: column;
  height:100PX;
  padding: 15PX 15PX;
  margin: 15PX 10PX;
  border-radius: 10PX;
  justify-content: space-between;
  background: #fff;
}
.servicelistItem .servicelistItemTop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50PX;
}
.servicelistItem .servicelistItemTop .servicelistItemImage{
  width: 40PX;
  height: 40PX;
  border-radius: 50%;
}
.servicelistItem .servicelistItemTop .servicelistItemText{
  margin-left: 25PX;
  font-size: 18PX;
  font-weight: 700;
  color: #000;
  flex: 1;
}
.servicelistItem .servicelistItemTop .servicelistItemBtn{
  display: flex;
  padding: 0 10PX;
  height: 30PX;
  border-radius: 15PX;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-180deg,#fc78af,#f22678)
}
.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText{
  color: #fff;
  font-size: 18PX;
}
.servicelistItem .servicelistItemBottom{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50PX;
  background: #f2f2f5;
  border-radius: 10PX;
  color: #979799;
}
.servicelistItem .servicelistItemBottom .servicelistItemInfoText{
  font-size: 15PX;
}
</style>
