<template>
  <div class="game">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__title van-ellipsis" style="padding-top: 2px;">{{ $t("text.txt15") }}</div>
      </div>
    </div>
    <div class="data">
      <div class="item"  @click="video()">
        <div class="left">
          <i class="van-badge__wrapper van-icon" style="font-size: 30px;">
          <img src="/img/game/video.png" class="van-icon__image"></i>
          <div>{{ $t("text.txt16") }}</div>
        </div>
        <div class="right">
          <span>{{ $t("text.txt17") }}</span>
          <div class="van-badge__wrapper" style="height: 32px;">
            <i class="van-badge__wrapper van-icon" style="font-size: 30px;">
              <img src="/img/game/user_avatar.png" class="van-icon__image">
            </i>
            <div class="van-badge van-badge--top-right van-badge--dot van-badge--fixed"></div>
          </div>
          <i class="van-badge__wrapper van-icon van-icon-arrow"></i>
        </div>
      </div>
      <div class="item" @click="choose()">
        <div class="left">
          <i class="van-badge__wrapper van-icon" style="font-size: 30px;">
            <img src="/img/game/welfare.png" class="van-icon__image"></i>
          <div>{{ $t("text.txt18") }}</div>
        </div>
        <div class="right">
          <span>{{ $t("text.txt19") }}:{{ Math.floor(Math.random() * 1000) + 100 }}***</span>
          <div class="van-badge__wrapper" style="height: 32px;">
            <i class="van-badge__wrapper van-icon" style="font-size: 30px;">
              <img src="/img/game/user_avatar.png" class="van-icon__image"></i>
            <div class="van-badge van-badge--top-right van-badge--dot van-badge--fixed"></div>
          </div>
          <i class="van-badge__wrapper van-icon van-icon-arrow"></i></div>
      </div>
    </div>
    <div class="gameCard">
      <div class="item" v-for="(itme,key) in gameitem" :key="key"  @click="toLottery(itme.key,itme.id)">
        <div class="dfs">
          <i class="van-badge__wrapper van-icon">
            <img src="/img/game/wangguan.png" class="van-icon__image">
          </i> {{ $t("text.txt14") }}
        </div>
        <p>{{ itme.name }}</p>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant';

export default {
  data() {
    return {
      gameitem: [{}, {}, {}, {}],
      lotteryitem: [{}, {}, {}, {}],
      isLoading: false,
      activeKey: 0,
    };
  },
  methods: {
    video(){
      this.$router.push({path:'/Video'})
    },
    choose(){
      this.$router.push({path:'/Choose'})
    },
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t("msg.刷新成功！"));
        this.isLoading = false;
      }, 500);
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/Lottery?key=' + key + "&id=" + id})
      }
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data: {class: index},
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    getLotteryItem() {
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res => {
        this.lotteryitem = res.data;
      })
    }
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    //this.getLotteryItem();
  }
};
</script>

<style lang='less' scoped>


</style>
