<template>
  <div id="app">
      <router-view></router-view>
      <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status:0
    };
  },
  methods: {
    getlang(){

    },
    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{
        /*if(!localStorage.getItem('token')){
          this.$router.push({path:'/Login'})
        }*/
        this.$store.commit('setBaseInfoValue', res.data);
      })
    }

  },
  created(){
    this.getlang();
    this.getBaseInfo();

  }
}
</script>

<style>

</style>
